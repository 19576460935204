import Stay_Calm_Img from '../Images/StayCalm.JPG'
import Spotify_Visualizer_Img from '../Images/SpotifyVisualizer.JPG'
import DND_Img from '../Images/D&D_Character_Creator.png'
import Spotify_Lyrics_Img from '../Images/LyricsForSpotify.JPG'
import Rock_Paper_Scissors_Img from '../Images/Rock_Paper_Scissors_Title.JPG'


const ProjectData = {
    PersonalPortfolio:
    {
        "Title": "Personal Portfolio Website",
            "Year": "2023",
            "Description": "This website was my project for over a year to challenge myself to migrate my personal website to something I created. I wanted to include video game aspects and showcase some of my web development skills.",
            "Skills":["JavaScript","React.js","AWS S3","HTML","CSS", "BootStrap 5", "Github Actions"],
            "NewSkillsLearned": [],
            "Image": "https://kirstensaling.com"
    },
    StayCalm:
        {
            "Title": "Stay Calm",
            "Year": "2023",
            "Description": "Game prototype to keep your character calm as the panic builds. This is one of my first solo game development projects. This was to help teach me how to build a first person experience during moments of panic. This includes delayed movement and adjusting visuals such as widened peripheral and blurred vision. This utilizes line tracing for determining peripheral vision and direct sight.",
            "Skills":["Unreal Engine 4","Meta Human","First Person Perspective","C++","UMG"],
            "NewSkillsLearned": [],
            "Image": Stay_Calm_Img
        }, 
    SpotifyVisualizer: {
            "Title": "Visualizer for Spotify",
            "Year": "2021",
            "Description": "This is a Javascript web application that utilizes the Spotify Web App API to control the users song data. The application uses the beats and tatums to create a visualization for the current song playing along with the album art.",
            "Skills":["JavaScript","HTML","CSS","Node.JS","Express", "Restful API"],
            "NewSkillsLearned": [],
            "Image": Spotify_Visualizer_Img
        }, 
    DND: {
        "Title": "Dungeons and Dragons 5th Edition Character Creation",
            "Year": "2019",
            "Description": "This project started with prompting the user through the command line and guiding them through the steps to create a character for Dungeons and Dragons. As the different character classes were being implemented it was determined that it would be beneficial to implement a graphical user interface for the user. This was my first project to learn JavaFX and how to add the GUI to my java applications. This application guides the user through setting up the character’s basic information such as name and age, set up the character’s ability scores, and add provide the user the options on leveling their character based on race and class. There are two options upon application start up, “Login” and “Continue Offline”, at the moment the login functionality is implemented with a MySQL server although it is not secure and should only be created with test data, to override the login option there is the continue offline mode where it will not enforce a login at that time.",
            "Skills":["Java 8","JavaFX","MySQL"],
            "NewSkillsLearned": [],
            "Image": DND_Img
    },
    SpotifyLyrics: {
        "Title": "Lyrics for Spotify",
            "Year": "2020",
            "Description": "This project is to allow a user to login into their Spotify profile, and through get their current song from Spotify and populate the lyrics for that song utilizing Spotify and APISeeds lyric API’s. The application features automatic scrolling for convenience of the lyrics showing as the song plays, as well as a streamer friendly option that skips songs automatically if there are no lyrics. There is a basic settings option to allow text and border size and color for user customization.",
            "Skills":["Java 8","JavaFX","API Handling","JSON Responses","OAuth 2.0 Authentication"],
            "NewSkillsLearned": [],
            "Image": Spotify_Lyrics_Img
    },
    RockPaperScissors: {
        "Title": "Rock, Paper, Scissors!",
            "Year": "2020",
            "Description": "This is a simple game of Rock, Paper, Scissors to familiarize myself with combining JavaScript functionality with HTML and stylizing with CSS. The application allows either two separate players to play head-to-head or allows solo players to play against a bot.",
            "Skills":["JavaScript","CSS","HTML", "Node.js", "Express"],
            "NewSkillsLearned": [],
            "Image": Rock_Paper_Scissors_Img
    },
 }

 export default ProjectData