import * as PIXI from 'pixi.js';

export async function parseSpritesheet(spritesheetImage, spritesheetJSON)
{
    var SpritesheetElement = new PIXI.Spritesheet(
        PIXI.Texture.from(spritesheetImage),
        spritesheetJSON
    );


    var spritesheetPromise = SpritesheetElement.parse();
    
    return await spritesheetPromise.then((value,error)=>{
        return value;
    });
}