
import Interactable from './Interactable';
class NPC extends Interactable
{
    constructor(texture, dialog)
    {
        super(texture)
        this.dialogOptions = dialog;

        this.STATE = {
            0: "AWAKE",
            1: "SLEEPING",
            2: "INTERACTING",
            3: "WALKING"
        }
    }

    setDialog(newDialog)
    {
        this.nextDialog = newDialog;
    }

    interact()
    {
        super.interact()
    }
// add textbox above object with letter to interact
    highlight()
    {
        super.highlight()
    }
}

export default NPC