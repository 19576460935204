import * as PIXI from 'pixi.js';
import TextBox from './TextBox';

class Interactable extends PIXI.AnimatedSprite
{

    constructor(texture, interactKey = 'E')
    {
        
        texture[0].defaultAnchor = (0.5,0.5)
        console.log(texture)
        super([texture[0]])

        this.interactKey = interactKey;
        this.addHighlightBoxToSprite()
        this.isInteracting = false;
        this.isActive = true;
        
    }

    interact()
    {

    }

// add textbox above object with letter to interact
    highlight()
    {
        this.interactText.show();
        // directionAsc = true means that the text box is going up, false means it's going down.
        var directionAsc = true
        if(!this.highlightInterval)
        {   
            this.highlightInterval = setInterval(() => {
                // If the direction is up and greater than 5 above the starting point, then go down
                if(directionAsc && this.interactTextSprite.y >= this.InteractTextStartingY + 5)
                {
                    directionAsc = false;
                    this.interactTextSprite.y--
                } 
                    // If the direction is down and less than 5 below the starting point, then go down
                else if(!directionAsc && this.interactTextSprite.y <= this.InteractTextStartingY - 5)
                {
                    directionAsc = true;
                    this.interactTextSprite.y++
                }
                // Else if the direction is down, go down 
                else if(!directionAsc)
                {
                    this.interactTextSprite.y--
                }
                // Else if the direction is up, go up  
                else if(directionAsc)
                {
                    this.interactTextSprite.y++
                }
            },100)
        }
        
    }

    setIsActive(activeState)
    {
        this.isActive = activeState;
    }

    getIsActive()
    {
       return this.isActive
    }

    setIsInteracting(newInteraction)
    {
        this.isInteracting = newInteraction;
    }

    getIsInteracting()
    {
        return this.isInteracting 
    }


    
    stopHighlight()
    {
        this.interactText.hide();
        this.isHighlighted = false;
        if(this.highlightInterval)
        {
            clearInterval(this.highlightInterval)
            this.highlightInterval=null
            this.interactTextSprite.y = this.InteractTextStartingY;
            this.interactTextSprite.x = this.InteractTextStartinX;
        }
    }

    async addHighlightBoxToSprite()
    {
        // TODO for some reason, when sprites are 32px, the center is 0 and when the sprite is 16px the center is -8. This is the current workaround but I need to determine why it's the case.
        var xPosition = -8 + (8 * ((this.width / 16) - 1));
        this.interactText = new TextBox ("E", true, 16, {x:xPosition, y:-32}, {width: 16, height: 16},"")
        this.interactTextSprite = await this.interactText.createSprite()
        this.InteractTextStartingY = this.interactTextSprite.y
        this.InteractTextStartinX = this.interactTextSprite.x
        this.interactText.hide()
        this.addChild(this.interactTextSprite)

    }
}

export default Interactable