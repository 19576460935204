import ObjectsData from "../../../../Images/Sprites/Object JSON.json"
import ObjectsSS from "../../../../Images/Sprites/Object Spritesheet.png"
import FontsData from "../../../../Images/Sprites/Font JSON.json"
import FontSS from "../../../../Images/Sprites/Font Spritesheet.png"
import {parseSpritesheet} from "../../../../Pages/Components/Game/Utils"
// import TextureEnum from "../Textures";
import * as PIXI from 'pixi.js';
class TextBox
{

    // Sets the text for the Textbox, and sets the default visibility
    constructor(text, visible, tileSize, position, size, textBoxType)
    {
        this.text = text;
        this.visible = visible;
        if(visible)
        {
            this.show();
        } else 
        {
            this.hide()
        }
        
        this.size = size;
        this.tileSize = tileSize;
        this.position = position;
        // TODO Add states to avoid conflicts
        this.loadTextures()
        
        this.hasMoreText = false;

        this.nextIndicator = new PIXI.Text({
            text: "Next >",
            style: 
                {fontFamily : 'Arial', 
                fontSize: 12 * (tileSize/16), 
                fill : 0x000000, 
                align : 'center',
                breakWords: true,
                wordWrap: true,
                wordWrapWidth: this.tileSize * (this.size.width / this.tileSize)}
        });
        this.nextIndicator.anchor = (.5,.75); 
        this.nextIndicator.x = tileSize * .75;
        this.nextIndicator.y = tileSize * .5;
        this.nextIndicator.visible = false;

    }

    setText(newText, hasMoreText = false)
    {
        this.text = newText; 
        this.textElement.text = newText;
        if(hasMoreText)
        {
            this.setHasMoreText(hasMoreText)
            this.nextIndicator.visible = true;
        } else 
        {
            this.setHasMoreText(hasMoreText)
            this.nextIndicator.visible = false;
        }
    }

    setHasMoreText(hasMoreText)
    {
        this.hasMoreText = hasMoreText
    }

    getHasMoreText()
    {
        return this.hasMoreText
    }

    show()
    {
        if(this.textContainer)
        {
            this.textContainer.visible=true;
        }
        
    }

    hide()
    {
        if(this.textContainer)
        {
            this.textContainer.visible=false;
        }
    }

    // Loads textures needed for text box
    async loadTextures ()
    {
        if(!this.textures)
        {
            await parseSpritesheet(ObjectsSS, ObjectsData).then((values) =>
            {
                this.textures = values;
            })
        }
    }

    async createSprite()
    {   
        if(!this.textures)
        {
            await this.loadTextures();
        }

        console.log(this.text)
        console.log(this.textures)
         // TODO get the width of the screen / tilesize (Math. floor)
         var maxWidth = Math.floor(this.size.width / this.tileSize);
         var height = Math.floor(this.size.height / this.tileSize);
         console.log("Max Width: " + maxWidth + ", Height: " + height)

        this.textContainer = new PIXI.Container();
        if(this.visible)
        {
            this.show()
        } else 
        {
            this.hide()
        }

        this.textContainer.x = this.position.x;
        this.textContainer.y = this.position.y;
        
        // Gets the text sprite
        this.textElement = new PIXI.Text({
            text: this.text,
            style: 
                {fontFamily : 'Arial', 
                fontSize: 12 * (this.tileSize/16), 
                fill : 0x000000, 
                align : 'center',
                breakWords: true,
                wordWrap: true,
                wordWrapWidth: this.tileSize * maxWidth}
            });
        this.textElement.anchor = (0,0);
        this.textElement.x = this.tileSize * .75;
        this.textElement.y = this.tileSize * .5;

        // console.log(`Text width: ${text.width}, Text Height: ${text.height}`)
        // console.log(`Text Position X: ${text.x}, Text Position Y: ${text.y}`)
        console.log("Textbox textures")
         console.log(this.textures)
         for (let hIndex = 0; hIndex <= height; hIndex++)
         {
             for (let wIndex = 0; wIndex <= maxWidth; wIndex++)
                 {
 
 
                     var sprite;
                     if(hIndex===0 && wIndex=== 0)
                     {
                         // console.log("h: " + hIndex + ", w: " + wIndex + ", p: tl")
                         sprite = new PIXI.Sprite(this.textures['1x1_chat_bubble_top_left.png'])
                         
                     }
                     else if (hIndex === height && wIndex=== 0)
                     {
                         // console.log("h: " + hIndex + ", w: " + wIndex + ", p: bl")
                         sprite = new PIXI.Sprite(this.textures['1x1_chat_bubble_bottom_left.png'])
                     }  else if (wIndex=== 0) 
                     {
                         // console.log("h: " + hIndex + ", w: " + wIndex + ", p: l")
                         sprite = new PIXI.Sprite(this.textures['1x1_chat_bubble_left.png'])
                     } else if(wIndex===maxWidth && hIndex ===0)
                     {
                         // console.log("h: " + hIndex + ", w: " + wIndex + ", p: tr")
                         sprite = new PIXI.Sprite(this.textures['1x1_chat_bubble_top_right.png'])
                     }  else if(wIndex===maxWidth && hIndex === height)
                     {
                         // console.log("h: " + hIndex + ", w: " + wIndex + ", p: br")
                         sprite = new PIXI.Sprite(this.textures['1x1_chat_bubble_bottom_right.png'])
                         this.nextIndicator.x = wIndex * this.tileSize;
                         this.nextIndicator.y = hIndex * this.tileSize;
                         this.nextIndicator.zIndex = 1
                         this.textContainer.addChild(this.nextIndicator);
                     } else if (wIndex===maxWidth)
                     {
                         // console.log("h: " + hIndex + ", w: " + wIndex + ", p: r")
                         sprite = new PIXI.Sprite(this.textures['1x1_chat_bubble_right.png'])
                     }
                     else if (hIndex === 0)
                     {
                         // console.log("h: " + hIndex + ", w: " + wIndex + ", p: t")
                         sprite = new PIXI.Sprite(this.textures['1x1_chat_bubble_top.png'])
                     }
                     else if (hIndex === height)
                     {
                         // console.log("h: " + hIndex + ", w: " + wIndex + ", p: t")
                         sprite = new PIXI.Sprite(this.textures['1x1_chat_bubble_bottom.png'])
                     } else 
                     {
                         // console.log("h: " + hIndex + ", w: " + wIndex + ", p: c")
                         sprite = new PIXI.Sprite(this.textures['1x1_chat_bubble_center.png'])
                             
                     }
 
                     sprite.x = wIndex * this.tileSize;
                     sprite.y = hIndex * this.tileSize;
                     sprite.scale = {x: Math.floor(this.tileSize / 16), y: Math.floor(this.tileSize / 16)}
                     sprite.texture._source.scaleMode = 'nearest'
                     sprite.roundPixels = true;
                     sprite.zIndex = 0;
                     this.textContainer.addChild(sprite);
                 }
            }   

        this.textContainer.addChild(this.textElement);
        return this.textContainer;
    }
}
export default TextBox