import { useEffect, useState } from "react";
import BookData from "../../Data/Books"
import "../../index.css"

var bookDataKeys = Object.keys(BookData);




function CODBookCallingCard()
{
    var [currentBookSelected, setCurrentBookSelected] = useState(BookData[bookDataKeys[0]])
    bookDataKeys.map((key) => {
        BookData[key].IsViewed = false;
    })

    var [BookKeyIndex, setBookKeyIndex] = useState(0);

    var [BookDataState, setBookDataState] = useState({...BookData, 
        [bookDataKeys[0]]: {IsViewed : true}});

    return (
        <div className="row mt-5 w-100">
            <div className="col-12 col-md-6">
                <div className="row row-cols-2 row-cols-xl-3 row-cols-xxl-4 mx-auto d-none d-md-flex">
                    {bookDataKeys.map((key) => {
                        
                        return (
                        <div className="col bookCard" key={key} type="button" onClick={()=>{
                            setCurrentBookSelected(BookData[key]); 
                            setBookDataState({
                                ...BookDataState,
                                [key]: {
                                    ...BookDataState[key],
                                    IsViewed: true
                                }
                            });}} style={{height: "fit-content" }}>
                            <div className="codBookImg" style={{ width: "100%", height: "15vh", background: "url("+BookData[key].Img +")", backgroundPosition: "bottom 75% left 50%",backgroundRepeat: "no-repeat", backgroundSize:"100%"}} ></div>
                            <div className="row codBookTitleBackground m-0">
                                <div className="col-10 ">
                                    <div className="codBookTitle" style={{height:"fit-content",  fontFamily:'IBM Plex Sans Condensed', color: "#bad7de"}}>{BookData[key].Title} </div>
                                </div>
                                {!BookDataState[key].IsViewed && <div className="col-2 p-1 d-flex align-items-end justify-content-end">
                                    <div className="d-flex align-items-center justify-content-center" style={{color: "green", borderStyle: "solid", margin: "0", padding:"auto", borderColor: "green", borderWidth: "1px", height: "23px", width: "23px"}}>
                                        <i className="bi bi-square-fill m-0 p-0" style={{color: "green", margin: "0", padding:"0"}}> </i>
                                    </div>
                                </div>}
                            </div>
                        </div>)
                    })}
                </div>
                <div className="d-block d-md-none">
                    <div id="callingCardCarousel" className="carousel slide mx-auto mb-5" data-ride="carousel" style={{width: "80%"}}>
                        <div class="carousel-inner">
                            {bookDataKeys.map((key, index) => {
                                return (<div className={"carousel-item " + (BookKeyIndex === index ? "active" : "")}>
                                    <div className="d-block w-100" key={key} style={{height: "fit-content" }}>
                                    <div className="codBookImg" style={{ width: "100%", height: "15vh", background: "url("+BookData[key].Img +")", backgroundPosition: "bottom 75% left 50%",backgroundRepeat: "no-repeat", backgroundSize:"100%"}} ></div>
                                    <div className="row codBookTitleBackground m-0">
                                        <div className="col-10 ">
                                            <div className="codBookTitle" style={{height:"fit-content",  fontFamily:'IBM Plex Sans Condensed', color: "#bad7de"}}>{BookData[key].Title} </div>
                                        </div>
                                        {!BookDataState[key].IsViewed && <div className="col-2 p-1 d-flex align-items-end justify-content-end">
                                            <div className="d-flex align-items-center justify-content-center" style={{color: "green", borderStyle: "solid", margin: "0", padding:"auto", borderColor: "green", borderWidth: "1px", height: "23px", width: "23px"}}>
                                                <i className="bi bi-square-fill m-0 p-0" style={{color: "green", margin: "0", padding:"0"}}> </i>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                </div>)
                            })}
                        </div>
                        <a className="carousel-control-prev text-white" href="#callingCardCarousel" role="button" data-slide="prev" onClick={()=>{
                                    let nextIndex = BookKeyIndex === 0 ? bookDataKeys.length - 1 : BookKeyIndex - 1;
                                    setBookKeyIndex(nextIndex);
                                    setCurrentBookSelected(BookData[bookDataKeys[nextIndex]]); 
                                    setBookDataState({
                                        ...BookDataState,
                                        [bookDataKeys[nextIndex]]: {
                                            ...BookDataState[bookDataKeys[nextIndex]],
                                            IsViewed: true
                                        }
                                    });
                        }}>
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </a>
                        <a className="carousel-control-next text-white" href="#callingCardCarousel" role="button" data-slide="next" onClick={()=>{
                                    let nextIndex  = BookKeyIndex + 1 === bookDataKeys.length ? 0 : BookKeyIndex + 1;
                                    setBookKeyIndex(nextIndex);
                                    setCurrentBookSelected(BookData[bookDataKeys[nextIndex]]); 
                                    setBookDataState({
                                        ...BookDataState,
                                        [bookDataKeys[nextIndex]]: {
                                            ...BookDataState[bookDataKeys[nextIndex]],
                                            IsViewed: true
                                        }
                                    });
                        }}>
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 d-flex flex-column align-items-center ">
                <div className="p-3" style={{backgroundColor: "rgba(0,0,0,.4)", width: "80%"}}>
                    <h1 style={{color: "white", fontSize: "clamp(20px, 5vw, 35px)"}}>{currentBookSelected.Title}</h1>
                    <div className="row mt-3">
                        <div className="col-12 col-xl-6">
                            <img src={currentBookSelected.Img} style={{width: '100%'}}></img>
                        </div>
                        <div className="col-12">
                            <p style={{color: "white"}}>{currentBookSelected.Description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CODBookCallingCard