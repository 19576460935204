import "../../index.css"
const bootstrap = require('bootstrap')

/**
 * 
 * @param {} props 
    props.dataBsTarget: If the item controlls a collapsible element, provide the ID of the element.
    props.ariaExpanded: If the item controlls a collapsible element, true or false if the element should be collapsed.
    props.dataBsToggle: If the item controlls a collapsible element, Collapse if it should collapse an element.
    props.title
* @returns 
 */
function DestinyInventoryPageSelector(props) { 
    //  console.log("dataBsTarget:" + props.dataBsTarget);
    //  console.log("ariaExpanded:" + props.ariaExpanded);
    //  console.log("dataBsToggle:" + props.dataBsToggle);
    //  console.log("title:" + props.title);
    //  console.log("Modal Element:" + props.modalElement);
     
        return(
        <div>
          {/* large Screens */}
          <button role="button" data-bs-toggle={props.dataBsToggle} className={ (props.icon) + ' destiny-inventory-page-selector shadow d-none d-md-block top-margin-5 btn d-flex align-items-center ' + (props.ariaExpanded === "true" ? "" : "collapsed")} data-bs-target={"#" +props.dataBsTarget} aria-expanded={props.ariaExpanded} aria-controls={props.dataBsTarget }>
          </button>

          {/* Small Screens */}
          <button role="button" data-bs-toggle={props.dataBsToggle} className={'bi shadow d-block d-md-none btn d-flex align-items-center text-white ' + (props.ariaExpanded === "true" ? "bi-circle" : "collapsed bi-circle-fill")} data-bs-target={"#" +props.dataBsTarget} aria-expanded={props.ariaExpanded} aria-controls={props.dataBsTarget }>
          </button>
        </div>
        

    );
}
export default DestinyInventoryPageSelector;