import "../../index.css"
import ExperienceData from "../../Data/Experience"
import {useEffect, useState } from 'react';
import "bootstrap-icons/font/bootstrap-icons.css";
import WhiteSniper from "../../Images/WhiteSniper.png"
import WhitePistol from "../../Images/WhitePistol.png"
import WhiteShotgun from "../../Images/WhiteShotgun.png"

const bootstrap = require('bootstrap')

const baseSkillHTML = <div id="codSkills" style={{position:'relative',backgroundColor: "grey", marginTop:"2vh", minHeight:"15%", background:"linear-gradient(#1A1818 10%, #221E1E 15%, #888383 55%, #9E9E9E 75%, #A4ABAB 90%)", fontFamily:'Helvetica', color:'antiquewhite'}}>
<p style={{fontWeight:'lighter', fontSize:'16px' , marginLeft:"10px", paddingTop: "10px"}}>Skills:</p>
</div>

//TODO Fix overlapping with footer
//TODO make mobile friendly

/**
 * 
 * @param {string} key The key within Experience.js object that needs to be displayed
 */
const updateDisplayedExperience = (key) => 
{
    
    var titleElm = document.getElementById("codTitle");
    var descriptionElm = document.getElementById("codDescription");

    if (titleElm)
    {
        titleElm.innerText = ExperienceData[key].Title
    }
     
    if (descriptionElm)
    {
        descriptionElm.innerText = ExperienceData[key].Description
    }

    updateCODImage(Object.keys(ExperienceData).findIndex((element) => element === key))
    updateExperienceYears(ExperienceData[key].TimeSpentGen)
    updateSkills(ExperienceData[key].Skills)
    updateAchievements(ExperienceData[key].Achievements)

    var element = document.querySelectorAll('[keyname="' + key + '"]');
    if(element.length > 0)
    {
        var selectedElms = document.getElementsByClassName("bi-circle-fill");

        for (let newElmIndex = 0; newElmIndex < element.length; newElmIndex++)
        {
            element[newElmIndex].classList.replace("bi-circle", "bi-circle-fill")
        }

        for (let removeElmIndex = 0; removeElmIndex < selectedElms.length; removeElmIndex++)
        {   
            selectedElms[removeElmIndex].classList .replace("bi-circle-fill", "bi-circle")
        }

        
    }
}

const updateCODImage = (index) =>  
{
    var expIconElm = document.getElementById("codExpIcon")

    var imageArray = [WhiteSniper, WhitePistol, WhiteShotgun]

    var imageIndex = index % imageArray.length;

    console.log(`Image Index: ${index}`)
    if(expIconElm)
    {
        
        expIconElm.innerHTML = "<img src='" + imageArray[imageIndex] + "' style='display: block; height: auto; width: auto; opacity:75%; max-width: 75%; max-height: 100px'></img>";
    }
    
}

/**
 * 
 * @param {string} key The key within Experience.js object that needs to be displayed
 */
const updateExperienceYears = (years) => 
{
    var expTimeElm = document.getElementById("codExpTime")
    expTimeElm.innerHTML = "";

    for (let experienceIndex = 0; experienceIndex < years; experienceIndex++)
    {
        expTimeElm.innerHTML += '<span class="bi bi-circle-fill mx-1" style="color:#6c4419; stroke: #321f0a; font-size:16px;"></span>';
        
    }

    if(years < 5)
    {
        for (let leftYears = 0; leftYears < 5 - years; leftYears++)
        {
            expTimeElm.innerHTML += ' <span class="mx-1 bi bi-circle" style="color:#6c4419; stroke: #321f0a; font-size:16px;"></span>'
        }
    }

}

/**
 * 
 * @param {Array} skills Array of skills (text / string) to be displayed in the skills box
 */
const updateSkills = (skills) => 
{
    console.log(skills)
    var skillsElm = document.getElementById("codSkills")
    var currentRow = 0;

    var skillIcons = ["award-fill", "backpack-fill", "bar-chart-fill", "book-fill", "briefcase-fill", "bricks", "brightness-high-fill", "bug-fill", "calculator","code-square", "cpu-fill", "database","device-hdd-fill", "device-ssd-fill", "floppy-fill", "lightning-charge-fill"]
    
    var skillColors = ['#EE8080', '#EEAD80', '#EEEE80', '#B9EE80', '#80EEA5', '#80EEDD', '#80CDEE', '#808EEE', '#9A80EE', '#C180EE', '#EE80E7']

    if(skillsElm)
    {
        skillsElm.innerHTML = "";

        skills.map((skill, index) => {
            var skillHTML = "";
            //If it is a new row
            if(index % 3 === 0) 
            {
                currentRow++;
                skillsElm.innerHTML +=  "<div id='skillRow" + currentRow + "' class='row justify-content-center row-cols-2 row-cols-md-3'></div>"
            }   
            //Else just add the skill

            skillHTML = "<div class='col'>" + skill + "</div>"

            var currentRowElm = document.getElementById("skillRow" + currentRow);
            if(currentRowElm)
            {
                //Gets a random index from the icon list
                var iconNum = Math.floor(Math.random() * skillIcons.length)
                //Gets a random index from the color list
                var colorNum = Math.floor(Math.random() * skillColors.length)

                currentRowElm.innerHTML+= "<div class='col my-2'><div class='row text-center' ><i class='bi bi-" + skillIcons[iconNum] + "' aria-hidden='true' style='font-size: 30px; color:" + skillColors[colorNum] + ";'></i></div><div class='text-center text-wrap text-break' style='min-height:25px; font-size: 12px;'>"+ skill[0] + "</div></div>"

                //Removes the icon from the list so there are no duplicates
                skillIcons.splice(iconNum,1);
            }
        })
    }
}

/**
 * 
 * @param {Array} achievements Array of achievements (text / string) to be displayed in the achievements box
 */
const updateAchievements = (achievements) => 
{
    var achievementElm = document.getElementById("codAchievements")
    var currentRow = 0;

    if(achievementElm)
    {
        achievementElm.innerHTML = "";

        achievementElm.innerHTML +=  "<ul id='achievementList'></ul>"
        var achievementListElm = document.getElementById("achievementList");

        if(achievementListElm)
        {
            achievements.map((achievement, index) => {
                achievementListElm.innerHTML += "<li style='font-weight:400; font-size:16px; color:rgba(255,255,255,.6);'>" + achievement + "</li>"                
            })
        }
        
    }
}

/**
 * 
 * @param {} props 
 * props.expData = Object of Work Experience
 * @returns 
 */
function CODExperience() 
{ 
    // var selectedExperience = document.getElementsByClassName("bi-circle-fill")
    
    useEffect(() => {
        updateAchievements(visibleExp.Achievements);
        updateSkills(visibleExp.Skills)
        updateExperienceYears(visibleExp.TimeSpentGen)
        updateCODImage(0)
    },[])

    

   //Get "First" key
    let firstKey = Object.keys(ExperienceData)[0];
    var visibleExp = ExperienceData[firstKey];
    
    var [selectedExperience, setSelectedExperience] = useState(firstKey);
    return(
        <div className='container-fluid' style={{height:"100%", width:"100%"}}>
            {/* Experience Selector for Large Screens */}
            <div className='row'>
              <div className='col-1 px-0 col-lg-4 top-margin-5  d-flex flex-column align-items-end'>
                {Object.keys(ExperienceData).map((expKey,index)  => {

                    return <div  style={{width:"fit-content"}}>
                            <button className="codExpButton d-flex d-none d-lg-block" role="button" key={expKey} onClick={()=>{updateDisplayedExperience(expKey); setSelectedExperience(expKey);}}  style={{opacity: (selectedExperience===expKey ? "1" : ".7"), width:"350px"}}>
                                <div className="codExpButtonOverlay text">{ExperienceData[expKey].Title}
                                    <span className={"bi " + (selectedExperience===expKey ? "bi-circle-fill" : "bi-circle")}></span>
                                </div>
                            </button>
                            <button className="codExpButtonSm d-flex d-block d-lg-none px-0 ms-1" role="button" key={expKey + "-sm"} onClick={()=>{updateDisplayedExperience(expKey); setSelectedExperience(expKey);}}  style={{opacity: (selectedExperience===expKey ? "1" : ".7"), background:"transparent"}}>
                                <span className={"button bi " + (selectedExperience===expKey ? "bi-circle-fill" : "bi-circle")} style={{padding:"0"}}></span>
                            </button>
                            
                        </div>
                })}
              </div>
               
               {/* Displayed experience */}
              <div className='col-10 col-md-6 top-margin-5 justify-content-center' style={{minWidth:'250px',maxWidth: "600px"}}>
                
                {/* Title and Description */}
                <div className="" style={{position:'relative',backgroundColor: "grey", marginTop:"2vh", marginBottom:"2vh", height:"fit-content", background:"radial-gradient(ellipse 900% 40% at bottom, #fba82f 3% , transparent 5%), radial-gradient(ellipse 250% 30% at bottom, #ba7f2f 5% , transparent 15%), radial-gradient(ellipse 100% 60% at bottom, rgba(194,98,16) 10%, #8C531B 40%,  transparent 100% ), linear-gradient(#201306 10%, #3C230A 40%, #432307 75%)", fontFamily:'IBM Plex Sans Condensed', color:'antiquewhite', padding: '10px', minWidth:"200px"}}>
                  <div className="row pt-1 px-3">
                    <div className="col-6">
                        <p style={{fontWeight:'400', fontSize:'24px', marginBottom: "1px", color: "rgba(255,255,255,.7)", stroke: "#514945"}}>Job Title:</p>        
                        <p id="codTitle" style={{fontWeight:'400', fontSize:'16px', color:"rgba(103,87,74,.8)"}}>{visibleExp.Title}</p>          
                    </div>
                    <div className="col-6 d-flex justify-content-end" id="codExpTime">
                        
                    </div>
                  </div>

                  <div className="row d-flex justify-content-center" id="codExpIcon">
                    
                  </div>
                  <div className="row px-3 mt-0">
                    <p style={{fontWeight:'400', fontSize:'14px' , marginBottom: "2px", color:"rgba(255,255,255,.6)"}}>Description:</p>
                    <p id="codDescription" style={{fontWeight:'200', fontSize:'12px', color:"rgba(255,255,255,.6)" }}>{visibleExp.Description}</p>
                  </div>
                </div>

                {/* Skills */}
                <div style={{position:'relative', marginTop:"2vh", backgroundColor: "grey", minHeight:"15%", 
                    background:"radial-gradient(ellipse 900% 40% at bottom, #dde4e5 3% , transparent 5%), radial-gradient(ellipse 250% 30% at bottom, #6e7273 5% , transparent 15%), radial-gradient(ellipse 100% 70% at bottom, #babfc1 10%, #989e9e 15%, #2e2f31 85%, transparent 100%), linear-gradient(#0b0d0c 10%, #2e2f31 40%, #252726 75%)", color:'antiquewhite' ,fontFamily:'IBM Plex Sans Condensed',  padding: '10px', minWidth:"200px"}}>

                    {/* background:"radial-gradient(ellipse 500% 40% at bottom, #dde4e5 3% , transparent 10%), radial-gradient(ellipse 400% 60% at bottom ,#babfc1, #989e9e,  transparent 40%),linear-gradient(#1A1818 10%, #221E1E 15%, #888383 75%)" */}
                    <div className="row pt-1 px-3">
                        <p style={{fontWeight:'400', fontSize:'24px', marginBottom: "1px", color: "rgba(255,255,255,.7)", stroke: "#514945"}}>Skills:</p>

                        <div id="codSkills" className="px-2 py-2">
                    </div>
                    </div>
                </div>

                {/* Achievements */}
                <div style={{position:'relative',backgroundColor: "grey", marginTop:"2vh", minHeight:"15%", background:"radial-gradient(ellipse 900% 40% at bottom, #addd7c 3% , transparent 5%), radial-gradient(ellipse 250% 30% at bottom, #6e7273 5% , transparent 15%), radial-gradient(ellipse 100% 70% at bottom, #58783b 15%, #27361a 85%, transparent 100%), linear-gradient(#1A251A 10%, #1B301B 40%, #27361a 75%)", fontFamily:'IBM Plex Sans Condensed', color:'antiquewhite', padding: '10px', minWidth:"200px" }}>
                    {/* background:"radial-gradient(ellipse 500% 40% at bottom, #addd7c 3% , transparent 10%), radial-gradient(ellipse 400% 60% at bottom ,#88b960, #6c9847,  transparent 40%),linear-gradient(#1A251A 10%, #1B301B 15%, #527A52 75%)" */}
                    <div className="row pt-1 px-3">
                        <p style={{fontWeight:'400', fontSize:'24px', marginBottom: "1px", color: "rgba(255,255,255,.7)", stroke: "#514945"}}>Achievements:</p>
                        <div id="codAchievements" className="px-2 py-2">
                    </div>
                </div>
                </div>
              </div>
              </div>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin=""/>
                <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet"></link>
            </div>
    );
}
export default CODExperience;