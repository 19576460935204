const skillCategory = {
    0: "Development",
    1: "Data Analyst",
    2: "Miscellaneous"
}
const SkillData = {
    JavaScript:
    {
        "Label Name": "JavaScript",
        "Category": skillCategory[0],
        "ExperiencePCT": 80
    }, 
    Node:
    {
        "Label Name": "Node.js",
        "Category": skillCategory[0],
        "ExperiencePCT": 60
    }, 
    Vue:
    {
        "Label Name": "Vue.js",
        "Category": skillCategory[0],
        "ExperiencePCT": 60
    }, 
    GAS:
    {
        "Label Name": "Google App Script",
        "Category": skillCategory[0],
        "ExperiencePCT": 75
    }, 
    Tailwind:
    {
        "Label Name": "Tailwind",
        "Category": skillCategory[0],
        "ExperiencePCT": 65
    }, 
    Bootstrap:
    {
        "Label Name": "Bootstrap",
        "Category": skillCategory[0],
        "ExperiencePCT": 80
    }, 
    HTML:
    {
        "Label Name": "HTML",
        "Category": skillCategory[0],
        "ExperiencePCT": 80
    }, 
    CSS:
    {
        "Label Name": "CSS",
        "Category": skillCategory[0],
        "ExperiencePCT": 70
    }, 
    CPP:
    {
        "Label Name": "C++",
        "Category": skillCategory[0],
        "ExperiencePCT": 55
    }, 
    Java:
    {
        "Label Name": "CSS",
        "Category": skillCategory[0],
        "ExperiencePCT": 70
    }, 
    Git:
    {
        "Label Name": "Git",
        "Category": skillCategory[0],
        "ExperiencePCT": 60
    }, 
    UnrealEngine:
    {
        "Label Name": "Unreal Engine",
        "Category": skillCategory[0],
        "ExperiencePCT": 60
    }, 
    AWS:
    {
        "Label Name": "Amazon Web Services",
        "Category": skillCategory[0],
        "ExperiencePCT": 70
    }, 
    Docker:
    {
        "Label Name": "Docker",
        "Category": skillCategory[0],
        "ExperiencePCT": 60
    }, 
    SQL:
    {
        "Label Name": "SQL",
        "Category": skillCategory[1],
        "ExperiencePCT": 70
    }, 
    SSMS:
    {
        "Label Name": "SQL Server Management Studio",
        "Category": skillCategory[1],
        "ExperiencePCT": 70
    }, 
    SSIS:
    {
        "Label Name": "SQL Server Integration Services",
        "Category": skillCategory[1],
        "ExperiencePCT": 75
    }, 
    VBA:
    {
        "Label Name": "VBA",
        "Category": skillCategory[1],
        "ExperiencePCT": 70
    }, 
    DAX:
    {
        "Label Name": "DAX",
        "Category": skillCategory[1],
        "ExperiencePCT": 70
    }, 
    M:
    {
        "Label Name": "M",
        "Category": skillCategory[1],
        "ExperiencePCT": 70
    }, 
    MicrosoftOffice:
    {
        "Label Name": "Microsoft Office",
        "Category": skillCategory[1],
        "ExperiencePCT": 90
    }, 
    VisualStudio:
    {
        "Label Name": "Visual Studio",
        "Category": skillCategory[1],
        "ExperiencePCT": 60
    }, 
    SharePoint:
    {
        "Label Name": "SharePoint",
        "Category": skillCategory[1],
        "ExperiencePCT": 75
    }, 
    PowerAutomate:
    {
        "Label Name": "Power Automate",
        "Category": skillCategory[1],
        "ExperiencePCT": 50
    }, 
    PowerBI:
    {
        "Label Name": "Power BI",
        "Category": skillCategory[1],
        "ExperiencePCT": 65
    }, 
    Excel:
    {
        "Label Name": "Excel",
        "Category": skillCategory[1],
        "ExperiencePCT": 80
    }, 
    PositiveAttitude:
    {
        "Label Name": "Positive Attitude",
        "Category": skillCategory[2],
        "ExperiencePCT": 100
    },
    CrossFunctionalCollaboration:
    {
        "Label Name": "Cross Functional Collaboration",
        "Category": skillCategory[2],
        "ExperiencePCT": 85
    },  
    Agile:
    {
        "Label Name": "Agile Methodology",
        "Category": skillCategory[2],
        "ExperiencePCT": 80
    }, 
    RegressionTesting:
    {
        "Label Name": "Regression Testing",
        "Category": skillCategory[2],
        "ExperiencePCT": 75
    }, 
    MobileTesting:
    {
        "Label Name": "iOS and Android device testing",
        "Category": skillCategory[2],
        "ExperiencePCT": 80
    }, 
    SDLC:
    {
        "Label Name": "Software Development Life Cycle",
        "Category": skillCategory[2],
        "ExperiencePCT": 70
    }, 
    TechnicalSupport:
    {
        "Label Name": "Technical Support",
        "Category": skillCategory[2],
        "ExperiencePCT": 90
    }, 
    DefectTrackingSoftware:
    {
        "Label Name": "Defect & Bug tracking software",
        "Category": skillCategory[2],
        "ExperiencePCT": 90
    }, 
    ProblemResolution:
    {
        "Label Name": "Problem Resolution",
        "Category": skillCategory[2],
        "ExperiencePCT": 85
    }, 
    ToolCreation:
    {
        "Label Name": "Tool Creation",
        "Category": skillCategory[2],
        "ExperiencePCT": 80
    }, 
    ClientCommunication:
    {
        "Label Name": "Client Communication",
        "Category": skillCategory[2],
        "ExperiencePCT": 95
    }, 
    ProcessAutomation:
    {
        "Label Name": "Process Automation",
        "Category": skillCategory[2],
        "ExperiencePCT": 85
    }, 
    DefectManagement:
    {
        "Label Name": "Defect Management",
        "Category": skillCategory[2],
        "ExperiencePCT": 95
    }, 
    CrititcalThinking:
    {
        "Label Name": "Critical Thinking",
        "Category": skillCategory[2],
        "ExperiencePCT": 85
    },
    
 }

 const getSkillPercent = (skillName) =>
 {
    let skillList = Object.entries(SkillData).filter(skill => 
    {
        console.log(skill)
        if(skill[1]["Label Name"] === skillName)
        {
            return skill[1]
        }
    })
    if (skillList.length === 1)
    {
        return skillList[0][1].ExperiencePCT
    } else return 0

 }

 export default {SkillData, getSkillPercent}