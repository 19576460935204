import "../../index.css"
const bootstrap = require('bootstrap')

var autoScrollInterval = setInterval(()=>{
    var element = document.getElementsByClassName("horizontalScroll")[0];

    if(element)
    {
        var first = element.firstChild;

        if(!isElementInViewport(first))
        {
            //Append to the end
            element.removeChild(first)
            element.appendChild(first);
            element.scrollTo(element.scrollLeft - first.offsetWidth,0)
                
        }

        element.scrollTo(element.scrollLeft + 1,0)
    }
    
},50)
/**
 * 
 * @param {} props 
 * props.array = Array of items to be scrolled through
* @returns 
 */
function HorizontalScroll(props) { 
    

    return(
        <div className="horizontalScroll hideScrollbar" onMouseEnter={()=>{stopAutoScroll()}} onMouseLeave={()=>{startAutoScroll()}}>
            {props.array.map((el)=>{
                return(el);
            })}
        </div>      
    );
}

// Returns if the element is currently in the viewport.
// Source: https://stackoverflow.com/questions/65460063/javascript-repeat-smoothly-automatic-horizontal-scrolling
function isElementInViewport(el)
{
    var rect = el.getBoundingClientRect();
    // console.log("Is in viewport: " + rect.right > 0);
    return rect.right > 0;

}

function startAutoScroll()
{
    autoScrollInterval = setInterval(()=>{
        var element = document.getElementsByClassName("horizontalScroll")[0];


        //If there is an element continue the function
        if (element)
        {
            var first = element.firstChild;
        
            if(!isElementInViewport(first))
            {
                //Append to the end
                element.removeChild(first)
                element.appendChild(first);
                element.scrollTo(element.scrollLeft - first.offsetWidth,0)
                    
            }

            element.scrollTo(element.scrollLeft + 1,0)
        }
    },50)
}

function stopAutoScroll()
{
    clearInterval(autoScrollInterval);
}
export default HorizontalScroll;