import DestinyInventoryModal from "./DestinyInventoryModal"

function DestinyInventoryItem(props) { 
    console.log(`Destiny Iventory Item: Empty ${props.empty}`)
    console.log(`Destiny Iventory Item: quantity ${props.quantity}`)
    console.log(`Destiny Iventory Item: image ${props.image}`)
    console.log(`Destiny Iventory Item: rarity ${props.rarity}`)
    console.log(`Destiny Iventory Item: Inventory Type ${props.inventoryType}`)
    console.log(`Destiny Iventory Item: ID ${props.id}`)

    return(<div className={"col " + (props.empty==="true" ? "emptyInventorySpace" : "inventorySpace " + props.rarity)}>
        <div  {...(props.empty==="false" && { type: "button" , })} data-bs-toggle="modal" data-bs-target={"#" + props.id + "Modal"} className="d-flex justify-content-start align-items-end">
        {props.quantity > 1 && <div style={{textAlign: "center",fontWeight: "normal", fontSize: "14px",padding: "3px", width: "fit-content", background: "white", aspectRatio: "1", bottom:0, left:0}}>{props.quantity < 10 ? "0" + props.quantity : props.quantity}</div>}
        </div>

        {props.empty === "false" && <DestinyInventoryModal id={props.id + "Modal"} dataKey={props.dataKey} inventoryType={props.inventoryType} ></DestinyInventoryModal>}
        
    </div>)
    
}

export default DestinyInventoryItem