import TestimonialData from "../../Data/Testimonials"
import { useState, useEffect } from 'react';

function CODFooter() 
{ 
    var testimonialCharIndex = 0;
    var quoteIndex = 0;
    const testimonialKeys = Object.keys(TestimonialData);
    var typingSpeedMS = 100;

    const [testimonialText,setTestimonialText] = useState("");

    const [shownTestimonials,setShownTestimonials] = useState([{"author": "", "quote": ""}]);
    var [testimonialIndex, setTestimonialIndex] = useState(0);


    function displayTestimonial(author, quote)
    {
     
        setTestimonialIndex(testimonialIndex++)

        if(testimonialIndex >= testimonialKeys.length - 1)
        {
            setShownTestimonials(shownTestimonials => ([
                ...shownTestimonials.slice(1),
                {"author": author,"quote": quote}
                ]));
        } else 
        {
            setShownTestimonials(shownTestimonials => ([
                ...shownTestimonials,
                {"author": author,"quote": quote}
                ]));
        }
        
    }

    function typeTestimonial()
    {
        var textbox = document.getElementById("textbox");

        if(textbox)
        {
        
            //Checks if it is the character in quote and sets to 0
            if(testimonialCharIndex === TestimonialData[testimonialKeys[quoteIndex]].Quote.length -1)
            {
                displayTestimonial(TestimonialData[testimonialKeys[quoteIndex]].Author,TestimonialData[testimonialKeys[quoteIndex]].Quote);

                testimonialCharIndex = 0;

                setTimeout(() => {
                    //Sets the text
                    var beginningIndex =  testimonialCharIndex - 45 < 0 ? 0 : testimonialCharIndex - 45;
                    setTestimonialText(TestimonialData[testimonialKeys[quoteIndex]].Quote.slice(0, testimonialCharIndex))
                    // textbox.scrollLeft += 10;
                    // console.log(`Scroll Left: ${textbox.scrollLeft}`)
                    // textbox.scrollTo(textbox.scrollLeft - 10, 0);

                    // Calls the function again
                    typeTestimonial();
                },typingSpeedMS * 10)
                //Checks if it is the last quote in list and sets to 0
                if (quoteIndex == testimonialKeys.length - 1)
                {
                    quoteIndex = 0;
                    
                    setTestimonialText("")
                } else 
                {
                    //Else it increments by one 
                    quoteIndex++;
                    
                }
            } else 
            {
                var maxCharacters = Math.floor(textbox.offsetWidth / 7)
                //Else it increments by one 
                testimonialCharIndex++;
                var beginningIndex =  testimonialCharIndex - maxCharacters < 0 ? 0 : testimonialCharIndex - maxCharacters;
                setTimeout(() => {
                    setTestimonialText(TestimonialData[testimonialKeys[quoteIndex]].Quote.slice(0, testimonialCharIndex))
                    textbox.scrollLeft += 15;
                    // textbox.scrollTo(textbox.scrollLeft - 10, 0);
                    typeTestimonial();
                },typingSpeedMS)
            }
                
        } else
        {
            console.log("Textbox not loaded")
        }
            
    }

    useEffect(() => {
        console.log("Effected")
        typeTestimonial();
    },[])


    return (
        <div>
            {/* Large Screens */}
            <div className="d-none d-lg-block">
                <div className="d-flex flex-row-reverse"  style={{height:"50px", width:"100%", backgroundColor:"rgba(132,132,132,.2)", position:"fixed",bottom:"0",left:"0", alignContent:"baseline", paddingRight: "20px"}}>
                    <div id="chat" className="col-3 d-flex flex-column align-content-end" style={{height: "fit-content", maxHeight:"60vh", color: "white", position:"absolute", bottom: "50px", verticalAlign:"bottom", overflowY:"hidden"}}>
                        {/* <div style={{fontSize: "12px"}}><span style={{color:"lightblue", marginBottom: "5px"}}>{shownAuthor} {shownAuthor !== '' && ":"}</span> {shownTestimonial}</div> */}
                        {shownTestimonials.map(testimonialObj => {
                                return testimonialObj && <div className="mt-3" style={{fontSize: "12px"}}><span style={{color:"lightblue", marginBottom: "5px"}}>{testimonialObj.author} {testimonialObj.author !== '' && ":"}</span> {testimonialObj.quote}</div>
                        })}
                        
                    </div>
                    <div className="d-flex px-0 col-3 align-items-center align-self-center justify-content-evenly" style={{height: "4vh", minHeight:"25px", background:"linear-gradient(to right, rgba(204,204,204,.2),transparent"}}>
                    <i className=' px-1 bi bi-chat-left-heart-fill' aria-hidden="true" style={{ fontSize: "12px", color:"lightgrey", fontWeight:"lighter"}}></i>
                    <div id="textbox" className="d-flex align-items-center" style={{width: "90%", height: "3vh", backgroundColor:"rgba(34,29,29,.8)", color:"white", fontSize: '12px', overflowX: "hidden", overflowY: "hidden",  paddingLeft: "1vh", paddingRight:"5px", textAlign:'end', whiteSpace:"nowrap"}}>{testimonialText}</div>  
                    </div>
                </div>
            </div>

            {/* Small Screens */}
            <div className="d-block d-lg-none">
            <button className="btn btn-light bi-chat-right-heart-fill" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{position: "absolute", zIndex: "10", borderRadius:"90px", bottom: "20px", right:"20px"}}></button>

            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{backgroundColor:"rgba(0,0,0,.85)", width: "100%"}}>
                <div class="offcanvas-header">
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" ><i className="bi bi-x" style={{color:"white", fontSize:"40px"}} > </i></button>
                </div>
                <div class="offcanvas-body">
                    <div className="d-flex flex-row-reverse justify-content-end ps-3"  style={{height:"50px", width:"100%", backgroundColor:"rgba(132,132,132,.2)", position:"fixed",bottom:"0",left:"0", alignContent:"baseline"}}>
                        <div id="chat" className="col-11 d-flex flex-column align-content-end" style={{height: "fit-content", maxHeight:"60vh", color: "white", position:"absolute", bottom: "50px", verticalAlign:"bottom", overflowY:"hidden"}}>

                            {shownTestimonials.map(testimonialObj => {
                                return testimonialObj && <div className="mt-3" key={testimonialObj.quote} style={{fontSize: '12px'}}><span style={{color:'lightblue', marginBottom: '5px'}}>{testimonialObj.author} {testimonialObj.author !== '' && ":"}</span> {testimonialObj.quote}</div>
                            })}
                        </div>
                        <div className="d-flex px-0 col-11 align-items-center align-self-center justify-content-evenly" style={{height: "4vh", minHeight:"25px", background:"linear-gradient(to right, rgba(204,204,204,.2),transparent"}}>
                            <i className=' px-1 bi bi-chat-left-heart-fill' aria-hidden="true" style={{ fontSize: "12px", color:"lightgrey", fontWeight:"lighter"}}></i>
                            <div id="textbox" className="d-flex align-items-center" style={{width: "90%", height: "3vh", backgroundColor:"rgba(34,29,29,.8)", color:"white", fontSize: '12px', direction:"rtl", overflowX: "hidden", overflowY: "hidden", textAlign:'end', whiteSpace:"nowrap"}}>{testimonialText}</div>  
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        
    )
}

export default CODFooter;