import "../../index.css"
const bootstrap = require('bootstrap')

/**
 * 
 * @param {} props 
 * props.title = Job Title
 *  props.timeSpent = Time spent at job
    props.description = Short Job description
    props.skills = 2D Array of Skills and their experience percentage
    props.achievements = Array of achievements
    props.id = ID that the element should be created with
    props.parent = Parent name for accordian
    props.show = If the element should be showing by default

 * @returns 
 */
function ExperienceCard(props) { 
    console.log("Job Title: " + props.title);
    console.log("Time Spent: " + props.timeSpent);
    console.log("Description: " + props.description);
    console.log("Skills: " + props.skills);
    console.log("Achievements: " + props.achievements);
    console.log("ID: " + props.id);
    console.log("Parent: " + props.parent);
    console.log("Show: " + props.show);
    return(
        <div className={'accordion-collapse collapse ' + props.show} id={props.id} data-bs-parent={props.parent} style={{minWidth:"300px",maxWidth:"600px"}}>
              {/* Title */}
              <div className='row' style={{height:"100px", backgroundColor:"#794299", borderTopStyle:"solid", borderTopWidth:"3px", borderTopColor:"wheat"}}>
                <h1 className='d-large-header pt-1 align-self-center mb-1' style={{textTransform: "uppercase"}}>{props.title}</h1>
                <h3 style={{color: "lightgrey", fontWeight: "normal", fontSize: "20px"}}>Cayuse Holdings</h3>
              </div>  
              {/* Title Icon */}
              <div className='row py-3' style={{borderBottomStyle:"solid", borderBottomWidth:"2px", borderBottomColor: "rgba(255,255,255,.5",  backgroundColor:"rgba(0,0,0,.8)"}}>
                <h1 className='d-sub-text mb-3'><span className='bi bi-bullseye' aria-hidden="true" style={{ color:"#794299"}}> </span>{props.timeSpent} Years Experience</h1>
                <h1 className='d-description' style={{ color:"white"}}>{props.description}</h1>
              </div>
              {/* Skills */}
              <div className='row' style={{ backgroundColor:"rgba(0,0,0,.8)"}}>
                <div className='row p-4'>
                  <div className='col-6'>
                    {/* https://stackoverflow.com/questions/69080597/×-typeerror-cannot-read-properties-of-undefined-reading-map */}
                    {props.skills?.map((skill, index) => {
                        if(index % 2 === 0)
                        {
                            return(
                                <div>
                                  <h3 className='d-general'>{skill[0]}</h3>
                                  <div className="progress destiny-progress mb-3" style={{height: "5px"}}>
                                    <div className="progress-bar bg-light" role="progressbar" style={{width: skill[1] + "%"}} aria-valuenow={skill[1]} aria-valuemin="0" aria-valuemax="100">{skill[1]}%</div>
                                  </div>
                                </div>
                            );
                        } else 
                        {
                            return;
                        }
                    })}
                  </div>
                  <div className='col-6'>
                  {/* https://stackoverflow.com/questions/69080597/×-typeerror-cannot-read-properties-of-undefined-reading-map */}
                  {props.skills?.map((skill, index) => {
                        if(index % 2 !== 0)
                        {
                            return(
                                <div>
                                  <h3 className='d-general'>{skill[0]}</h3>
                                  <div className="progress destiny-progress mb-3" style={{height: "5px"}}>
                                    <div className="progress-bar bg-light" role="progressbar" style={{width: skill[1] + "%"}} aria-valuenow={skill[1]} aria-valuemin="0" aria-valuemax="100">{skill[1]}%</div>
                                  </div>
                                </div>
                            );
                        } else 
                        {
                            return;
                        }
                    })}
                  </div>
                </div>
              </div>
              <div className="row p-3"  style={{ backgroundColor:"rgba(52, 49, 45, .9)"}}>
            {/* https://stackoverflow.com/questions/69080597/×-typeerror-cannot-read-properties-of-undefined-reading-map */}
            {/* Achievements  */}
              {props.achievements?.map((achievement, index) => {
                    return(
                      <div className="row mb-1">
                        
                        <h3 className='col d-general' key={achievement + " " + index} style={{lineHeight: "175%", fontStyle:"italic"}}><span><i className="col-1 d-general bi bi-check-circle-fill me-2"></i></span>{achievement}</h3>
                      </div>
                        
                    );
                })}
              </div>
              </div>
    );
}
export default ExperienceCard;