import SkillData from "./Skills.js"
function getYearDifference(date1, date2)
{   
    console.log(`Date 1:  ${date1.toString()} - Date 2: ${date2.toString()}`);

    // https://www.w3resource.com/javascript-exercises/javascript-date-exercise-49.php
    // Calculate the difference in milliseconds between the two dates
    var diff = (date1.getTime() - date2.getTime()) / 1000;
    // Convert the difference from milliseconds to days
    diff /= (60 * 60 * 24);
    // Calculate the approximate number of years by dividing the difference in days by the average number of days in a year (365.25)
    console.log(Math.abs(Math.round(diff / 365.25)))
    return Math.abs(Math.round(diff / 365.25));

}
const ExperienceData = {
    WebDevelopment:
        {
            "Title": "Web Development",
            "TimeSpent": "4+",
            "TimeSpentGen": getYearDifference(new Date(2020, 9, 1), new Date(Date.now())),
            "Description": "Developed two single page web applications with a small team.",
            "Skills":[["JavaScript", SkillData.getSkillPercent("JavaScript")],["Node.js", SkillData.getSkillPercent("Node.js")],["Vue.js", SkillData.getSkillPercent("Vue.js")],["Google App Script", SkillData.getSkillPercent("Google App Script")],["Amazon Web Services", SkillData.getSkillPercent("Amazon Web Services")], ["Tailwind", SkillData.getSkillPercent("Tailwind")],["Bootstrap", SkillData.getSkillPercent("Bootstrap")],["Git", SkillData.getSkillPercent("Git")]],
            "Achievements": ["Successfully implemented, tested, and released a data manipulation feature", "Resolved report timeout bug by optimizing reporting algorithm to reduce generation time by over 60%", "Sucessfully recovered user data that from the last year was stored in staging enviroment that reset every 24 hours "],
            "ID": "WebDevExp"
        }, 
    DataAnalyst: {
            "Title": "Data Analyst",
            "TimeSpent": "4+",
            "TimeSpentGen": getYearDifference(new Date(2020, 8, 1),new Date(Date.now())),
            "Description": "Supports three projects that require specific tasks from a Fortune 100 client ranging from data validations to finance report creation and distribution. Creates Power BI Reports to for Cayuse Holdings team leads for efficient reporting to clients.",
            "Skills":[["SQL", SkillData.getSkillPercent("SQL")], ["VBA", SkillData.getSkillPercent("VBA")],["DAX", SkillData.getSkillPercent("DAX")], ["M", SkillData.getSkillPercent("M")],["Excel", SkillData.getSkillPercent("Excel")], ["Microsoft Office", SkillData.getSkillPercent("Microsoft Office")], ["Visual Studio", SkillData.getSkillPercent("Visual Studio")], ["Power BI", SkillData.getSkillPercent("Power BI")], ["Power Automate", SkillData.getSkillPercent("Power Automate")], ["SharePoint", SkillData.getSkillPercent("SharePoint")], ["SQL Server Management Studio", SkillData.getSkillPercent("SQL Server Management Studio")], ["SQL Server Integration Services", SkillData.getSkillPercent("SQL Server Integration Services")]],
            "Achievements": ["Created data validation and formatting tools using that reduced the time to complete the validations by approximately 40%", "Developed Power Automation application for importing data into SharePoint and created a dynamic Power BI report."],
            "ID": "DataAnalystExp"
        }, 
    DefectManager: {
        "Title": "Defect Manager",
        "TimeSpent": "1+",
        "TimeSpentGen": getYearDifference(new Date(2018, 3, 1),new Date(2020, 8, 1)),
        "Description": "Managed three front end enterprise mobile applications active defects in all environments and development stages in a fast-paced Agile environment. Owned functions related to defect management in collaboration with development and testing teams acting as Tier 3 Helpdesk support.",
        "Skills":[["Agile Methodology", SkillData.getSkillPercent("Agile Methodology")], ["Defect Management", SkillData.getSkillPercent("Defect Management")],["Regression Testing", SkillData.getSkillPercent("Regression Testing")], ["iOS and Android device testing", SkillData.getSkillPercent("iOS and Android device testing")], ["Software Development Life Cycle", SkillData.getSkillPercent("Software Development Life Cycle")], ["Defect & Bug tracking software", SkillData.getSkillPercent("Defect & Bug tracking software")]],
        "Achievements": ["Resolved over 50% of project active defects within first 3 months on the project.", "Intermittently aided IST team with manual iOS and Android testing efforts resulting in quickly meeting testing deadlines and locating additional bugs."],
        "ID": "DefectManagerExp"
    }
 }

 export default ExperienceData