import Book_Math from '../Images/3D_Math.png'
import Book_Game_Dev_UE4 from '../Images/Game_Dev_With_UE4.png'
import Book_Beginning_CPP from '../Images/Beginning_CPP_Through_Game_Programming.jpg'
import Book_Cracking_Code_Interview from '../Images/Cracking_The_Coding_Interview.jpg'
import Book_Into_Java from '../Images/Introduction_to_Programming_In_Java.png'


const BookData = {
    
    GameDevMath: {
         "Title": "3D Math Primer for Graphics and Game Development",
         "Img": Book_Math,
         "Description": "3D Math Primer for Graphics and Game Development covers fundamental 3D math concepts that are especially useful for computer game developers and programmers. The authors discuss the mathematical theory in detail and then provide the geometric interpretation necessary to make 3D math intuitive. Important Notice: The digital edition of this book is missing some of the images or content found in the physical edition."
        }, 
     UnrealGameDev: {
        "Title": "Game Development Projects with UE4",
        "Img": Book_Game_Dev_UE4,
        "Description": "Kickstart your career or dive into a new hobby by exploring game design with UE4 and C++Learn the techniques needed to prototype and develop your own ideasReinforce your skills with project-based learning by building a series of games from scratchBook"
        }, 
    CPP: {
        "Title": "Beginning C++ through Game Programming",
        "Img": Book_Beginning_CPP,
        "Description": "Beginning C++ Through Game Programming, Second Edition approaches C++ from a game programming perspective unlike any other on the market. Offering a thorough and modern introduction to C++, this book is everything that an aspiring game programmer needs in order to learn the fundamentals of C++ and game programming basics. There is no programming experience necessary. You'll begin within the fundamental steps of C++ and game programming. The book presents a small, but complete program for each new concept"
        }, 
    CrackingCodeInt: {
        "Title": "Cracking the Coding Interview",
        "Img": Book_Cracking_Code_Interview,
        "Description": "Now in the 5th edition, Cracking the Coding Interview gives you the interview preparation you need to get the top software developer jobs. This book provides: 150 programming interview questions and answers; five proven ways to tackle the toughest algorithm problems; the top ten most common mistakes that candidates make; and, strategies to prepare for behavioural and technical questions."
        }, 
    JavaIntro: {
        "Title": "Introduction to Programming in Java",
        "Img": Book_Into_Java,
        "Description": "Introduction to Programming in Java is an interdisciplinary approach to the traditional CS1 curriculum with Java. We teach the classic elements of programming, using an “objects-in-the-middle” approach that emphasizes data abstraction. We motivate each concept by examining its impact on specific applications, taken from fields ranging from materials science to genomics to astrophysics to internet commerce."
        }
 }

 export default BookData

