import React, { useEffect } from 'react';
import Navbar from './Components/Navbar'
import portfolio from "../Data/Projects"
import Masonry from 'masonry-layout';


function Portfolio() {
  
  console.log(Object.entries(portfolio))

  useEffect(()=>
  {
    var msnry = new Masonry( '#portfolio-masonry', {itemSelector: ".card-col", percentPosition: true});
  },[])

  const listProjects = Object.entries(portfolio).map(project =>
    <div className='card-col col col-12 col-md-6 mb-4 mx-auto' key={project[0]}>
      <div className='card'>
        
        {project[1].Image.startsWith('http') ? <iframe src={project[1].Image} className="card-img-top" alt="..." style={{minHeight: "400px"}}></iframe>: <img src={project[1].Image} className="card-img-top" alt="..."></img>}
        <div className="card-body">
          <h5 className="card-title">{project[1].Title}</h5>
          <p className="card-text">{project[1].Description}</p>
          <div className='row d-flex justify-content-center'>
            {project[1].Skills.map(skill => {
              return <div className="col-auto portfolio-skill-label m-2 d-flex justify-content-center align-items-center">
                {skill}
              </div>
            })}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    
    <div className='default-bg p-0 m-0' style={{minHeight: "100vh", height: "max-content"}}>
      <Navbar theme="Default" selected_nav="portfolio" ></Navbar>
      <div className='container'>
        <div className="spacer-80"></div>
        <div className='row' id='portfolio-masonry'>
          {listProjects}
        </div>
    </div>
     
    </div>
  );
}

export default Portfolio;