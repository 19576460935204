
import * as PIXI from 'pixi.js';
import Interactable from './Sprites/Interactable'
import NPC from './Sprites/NPC';
import Sign from './Sprites/Sign';

import ObjectsData from "../../../Images/Sprites/Object JSON.json"
import ObjectsSS from "../../../Images/Sprites/Object Spritesheet.png"
import BlankNPCData from "../../../Images/Sprites/Blank NPC JSON.json"
import BlankNPCSS from "../../../Images/Sprites/Blank NPC Spritesheet.png"
import FontData from "../../../Images/Sprites/Font JSON.json"
import FontSS from "../../../Images/Sprites/Font Spritesheet.png"
import LogNPC from './Sprites/LogNPC';

class TextureEnum {
    static allTextures = {};

    constructor()
    {
        // PIXI.Assets.add({alias: "objects", src: ObjectsSS});
        // PIXI.Assets.add({alias: "fonts", src: FontSS});
        this.textureTypeEnum = {
            0 : "STATIC",
            1: "INTERACTABLE",
            2: "STANDING_NPC",
            3: "RUNNING_NPC",
            4: "SIGN",
            5: "ANIMATED",
            6: "LOG_NPC"
        }

        
    }

    async loadTextures(){
        console.log("All textures")
        console.log(TextureEnum.allTextures)

        PIXI.Assets.add({alias: "OVERWORLD_SS", src: ObjectsSS});
        PIXI.Assets.add({alias: "FONTS_SS", src: FontSS});
        PIXI.Assets.add({alias: "PLAYER_SS", src: BlankNPCSS});

        await PIXI.Assets.load(["OVERWORLD_SS", "FONTS_SS", "PLAYER_SS"]).then(async (textures)=>{
                // Create the SpriteSheet from data and image   
            var ObjectsSpritesheet = new PIXI.Spritesheet(                                                                                                                                                      
                textures.OVERWORLD_SS,
                ObjectsData
            );

            

            // Generate all the Textures asynchronously
            var ObjectsPromise = ObjectsSpritesheet.parse();
            await ObjectsPromise.then((value,error)=>{
                console.log(value)
                TextureEnum.allTextures.OVERWORLD_TEXTURES = value
            })
            
            // Create the SpriteSheet from data and image   
            var FontSpritesheet = new PIXI.Spritesheet(
                    textures.FONTS_SS,
                    FontData
                );
            
            var FontsPromise = FontSpritesheet.parse();
            await FontsPromise.then((value,error)=>{
                TextureEnum.allTextures.FONTS_TEXTURES = value
            })

            var BlankNPCSpritesheet = new PIXI.Spritesheet(
                textures.PLAYER_SS,
                BlankNPCData
            );

            var BlankNPCPromise = BlankNPCSpritesheet.parse();
            await BlankNPCPromise.then((value,error)=>{
                TextureEnum.allTextures.PLAYER_TEXTURES = value
            })
        });
    }

    async getTextures()
    {
        var textureEnum = {};
        var fontEnum = {}

        
    // const spritePromise  = PIXI.Assets.load(["objects","fonts"]);

    //     await spritePromise.then(async(textures)=>
    //     {
    //         // Create the SpriteSheet from data and image   
    //         var ObjectsSpritesheet = new PIXI.Spritesheet(
    //         textures.objects,
    //         ObjectsData
    //         );
    //         // Generate all the Textures asynchronously
    //         var ObjectsPromise = ObjectsSpritesheet.parse();
    //         await ObjectsPromise.then((value,error)=>{
    //             // PIXI.Assets.add({alias: "objectsParsed", src: value});
                var value = TextureEnum.allTextures.OVERWORLD_TEXTURES;
               
                textureEnum[1]= {texture: [value["1x1_grass_1.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[2]= {texture: [value["1x1_grass_2.gif"]], hasCollision: false,  interactable: false , type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[3]= {texture: [value["3x3_fountain_1.gif"], value["3x3_fountain_2.gif"], value["3x3_fountain_3.gif"]], hasCollision: true, interactable: false , type: this.textureTypeEnum[5], scale: {x: 2, y:2} }
                textureEnum[4]= {texture: [value["1x1_fence_horizontal.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[5]= {texture: [value["1x1_fence_vertical.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[6]= {texture: [value["1x1_fence_bottom_left_corner.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[7]= {texture: [value["1x1_fence_bottom_right_corner.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[8]= {texture: [value["1x1_fence_top_left_corner.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[9]= {texture: [value["1x1_fence_top_right_corner.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[10]= {texture: [value["2x2_fire_circle_1.png"], value["2x2_fire_circle_2.png"],value["2x2_fire_circle_3.png"],value["2x2_fire_circle_4.png"],value["2x2_fire_circle_5.png"],value["2x2_fire_circle_6.png"],value["2x2_fire_circle_7.png"], value["2x2_fire_circle_8.png"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[5], scale: {x: 2, y:2} }
                textureEnum[11]= {texture: [value["2x2_growing_bush_1.png"],value["2x2_growing_bush_2.png"],value["2x2_growing_bush_3.png"],value["2x2_growing_bush_4.png"],value["2x2_growing_bush_5.png"],value["2x2_growing_bush_6.png"],value["2x2_growing_bush_7.png"],value["2x2_growing_bush_8.png"],value["2x2_growing_bush_9.png"],value["2x2_growing_bush_10.png"],value["2x2_growing_bush_11.png"],value["2x2_growing_bush_12.png"],value["2x2_growing_bush_13.png"],value["2x2_growing_bush_14.png"],value["2x2_growing_bush_15.png"],value["2x2_growing_bush_16.png"],value["2x2_growing_bush_17.png"], value["2x2_growing_bush_18.png"]], hasCollision: false, interactable: false , type: this.textureTypeEnum[5], scale: {x: 2, y:2} } 
                textureEnum[12]= {texture: [value["2x2_tree_bush.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[13]= {texture: [value["1x1_bush.png"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[14]= {texture: [value["1x1_shrub_fence_top_left_corner.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[15]= {texture: [value["1x1_shrub_fence_top.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[16]= {texture: [value["1x1_shrub_fence_top_right_corner.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[17]= {texture: [value["1x1_shrub_fence_left.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[18]= {texture: [value["1x1_shrub_fence_right.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[19]= {texture: [value["1x1_shrub_fence_bottom_left_corner.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[20]= {texture: [value["1x1_shrub_fence_bottom.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[21]= {texture: [value["1x1_shrub_fence_bottom_right_corner.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[22]= {texture: [value["1x1_light_grass_bottom_left_dark_grass.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[23]= {texture: [value["1x1_light_grass_bottom_dark_grass.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[24]= {texture: [value["1x1_light_grass_bottom_right_dark_grass.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[25]= {texture: [value["1x1_light_grass_left_dark_grass.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[26]= {texture: [value["1x1_light_grass_right_dark_grass.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[27]= {texture: [value["1x1_light_grass_top_right_dark_grass.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[28]= {texture: [value["1x1_light_grass_top_dark_grass.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[29]= {texture: [value["1x1_light_grass_top_left_dark_grass.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[30]= {texture: [value["1x1_dark_grass.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[31]= {texture: [value["1x1_shrub_wall_top_end.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[32]= {texture: [value["1x1_shrub_wall_bottom_end.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[33]= {texture: [value["1x1_shrub_wall_left_end.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[34]= {texture: [value["1x1_shrub_wall_right_end.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[35]= {texture: [value["1x1_light_grass_top_left_corner_dark_green_grass.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[36]= {texture: [value["1x1_light_grass_top_right_corner_dark_green_grass.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[37]= {texture: [value["1x1_light_grass_bottom_left_corner_dark_green_grass.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[38]= {texture: [value["1x1_light_grass_bottom_right_corner_dark_green_grass.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[39]= {texture: [value["2x2_plaque.png"]], hasCollision: true, interactable: true, type: this.textureTypeEnum[4], scale: {x: 2, y:2}}
                textureEnum[40]= {texture: [value["2x2_grave_1.gif"]], hasCollision: true, interactable: true, type: this.textureTypeEnum[4], scale: {x: 2, y:2}}
                textureEnum[41]= {texture: [value["2x2_grave_2.gif"]], hasCollision: true, interactable: true, type: this.textureTypeEnum[4], scale: {x: 2, y:2}}
                textureEnum[42]= {texture: [value["1x1_sign_1.gif"]], hasCollision: true, interactable: true, type: this.textureTypeEnum[4], scale: {x: 2, y:2}}
                textureEnum[43]= {texture: [value["1x1_sign_2.gif"]], hasCollision: true, interactable: true, type: this.textureTypeEnum[4], scale: {x: 2, y:2}}
                textureEnum[44]= {texture: [value["2x2_fire_circle_1.png"],value["2x2_fire_circle_2.png"],value["2x2_fire_circle_3.png"],value["2x2_fire_circle_4.png"],value["2x2_fire_circle_5.png"],value["2x2_fire_circle_6.png"],value["2x2_fire_circle_7.png"],value["2x2_fire_circle_8.png"],], hasCollision: true, interactable: false, type: this.textureTypeEnum[5], scale: {x: 2, y:2} }
                textureEnum[45]= {texture: [value["1x1_book_closed.png"],value["1x1_book_open_one_fourth.png"],value["1x1_book_open_one_half.png"],value["1x1_book_open_three_fourth.png"],value["1x1_book_open_full.png"],], hasCollision: true, interactable: true, type: this.textureTypeEnum[5], scale: {x: 2, y:2}}
                textureEnum[46]= {texture: [value["1x1_growing_yellow.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[47]= {texture: [value["1x1_growing_green.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[48]= {texture: [value["1x1_growing_red.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[49]= {texture: [value["1x1_dirt.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[50]= {texture: [value["1x1_dirt_w_grass_top_left_corner.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[51]= {texture: [value["1x1_dirt_w_grass_top_right_corner.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[52]= {texture: [value["1x1_dirt_w_grass_bottom_left_corner.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[53]= {texture: [value["1x1_dirt_w_grass_bottom_right_corner.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[54]= {texture: [value["1x1_light_grass_bottom_left_dirt.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[55]= {texture: [value["1x1_light_grass_bottom_dirt.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[56]= {texture: [value["1x1_light_grass_bottom_right_dirt.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[57]= {texture: [value["1x1_light_grass_left_dirt.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[58]= {texture: [value["1x1_light_grass_right_dirt.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[59]= {texture: [value["1x1_light_grass_top_right_dirt.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[60]= {texture: [value["1x1_light_grass_top_dirt.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[61]= {texture: [value["1x1_light_grass_top_left_dirt.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[62]= {texture: [value["1x1_bush.png"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[63]= {texture: [value["1x1_flower_1.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[64]= {texture: [value["1x1_flower_2.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[65]= {texture: [value["1x1_flowers_green_1.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[66]= {texture: [value["1x1_flowers_green_2.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[67]= {texture: [value["1x1_flowers_green_3.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[68]= {texture: [value["1x1_rock_1.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[69]= {texture: [value["1x1_rock_2.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[70]= {texture: [value["1x1_rock_3.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[71]= {texture: [value["1x1_rock_4.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[72]= {texture: [value["1x1_rock_5.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[73]= {texture: [value["1x1_skeleton_bone_1.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[74]= {texture: [value["1x1_skeleton_bone_2.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[75]= {texture: [value["1x1_skeleton_bone_3.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[76]= {texture: [value["1x1_skeleton_bone_4.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[77]= {texture: [value["1x1_skeleton_bone_5.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[78]= {texture: [value["1x1_skeleton_bone_6.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[79]= {texture: [value["1x1_skeleton_bone_7.gif"]], hasCollision: false, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[80]= {texture: [value["1x2_barrel_1.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[81]= {texture: [value["1x1_crate.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[82]= {texture: [value["1x2_barrel_1.gif"]], hasCollision: true, interactable: false, type: this.textureTypeEnum[0], scale: {x: 2, y:2} }
                textureEnum[83]= {texture: [PIXI.Texture.EMPTY], hasCollision: true, interactable: true, type: this.textureTypeEnum[6], scale:{x: 1, y:1}}
            // });  

            // Create the SpriteSheet from data and image   
            // var FontSpritesheet = new PIXI.Spritesheet(
            //     textures.fonts,
            //     FontData
            //     );

            // var FontsPromise = FontSpritesheet.parse();
            // await FontsPromise.then((value,error)=>{
                // PIXI.Assets.add({alias: "fontsParsed", src: value});

                // fontEnum = value;
                fontEnum = PIXI.Assets.get("fonts")
            // })
            
        // })
        // console.log(textureEnum)
        
        
        this.overworldTextures = textureEnum;
        this.fontTextures = fontEnum;
        return {
            overworld: textureEnum,
            fonts: fontEnum,
        }
        
    } 

    setTextures(newTextures)
    {
        this.textures = newTextures;
    }

    static getAllTextures()
    {
        return TextureEnum.allTextures;
    }

    getSprite(textureNum, options ={}, textBoxContainer = undefined)
    {
        // console.log(`Get Sprite Texture # ${textureNum}`)
        
        var texture = this.overworldTextures[textureNum];
        // console.log(texture.type)
        var sprite;
        switch (texture.type)
        {
            case "STATIC":
                sprite = new PIXI.Sprite(texture.texture[0])
                
            break;
            case "ANIMATED":
                sprite = new PIXI.AnimatedSprite(texture.texture)
                
                sprite.animationSpeed = .1;
                sprite.play();
            
            break;
            case "INTERACTABLE":
                sprite = new Interactable(texture.texture)
            break;
            case "STANDING_NPC":
                sprite = new NPC(texture.texture)
            break;
            case "RUNNING_NPC":
                sprite = new NPC(texture.texture)
            break;
            case "SIGN":
                if(Object.keys(options).length > 0 && options.textArray.length > 0)
                {
                    sprite = new Sign(texture.texture, options.textArray, textBoxContainer )
                } else sprite = new Sign(texture.texture)
                
            break;
            case "LOG_NPC":
                if(Object.keys(options).length > 0 && options.textArray.length > 0)
                    {
                        sprite = new LogNPC(options.textArray )
                    } else sprite = new LogNPC([""])
                
            break;
        }
        // console.log(sprite)
        sprite.texture._source.scaleMode = 'nearest'
        // sprite.x = x * this.tileSize;
        // sprite.y = y * this.tileSize;
        sprite.scale = { x: texture.scale.x, y: texture.scale.y}
        sprite.roundPixels = true;

        return sprite;
    }
    
}




export default TextureEnum 