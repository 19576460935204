const TestimonialData = {
    PositiveAttitude:
        {
            "Title": "Positive Attitude",
            "Author": "Project Manager",
            "Quote": "Picks up any task assigned to her, learns the process, and becomes an expert at it. All with a positive attitude."
        }, 
    Organization: {
            "Title": "Organization",
            "Author": "Project Manager",
            "Quote": "Kirsten keeps track of everything and always has a well thought out answer when something is needed."
        }, 
    Persistence:{
            "Title": "Persistence",
            "Author": "Project Manager",
            "Quote": "Digs for information until she gets the answer she wants. She has a great follow through."
        }, 
    DetailOriented:{
            "Title": "Detail and Customer oriented",
            "Author": "Company newsletter",
            "Quote": "Kirsten Saling has taken on all aspects of the … program and has shown exemplary customer service to the client. She is detailed and follows each task through to completion. She documents, researches and looks for ways to improve the program and streamline the process. Kirsten shows integrity and dedication to … [the company]!"
        }
}

export default TestimonialData