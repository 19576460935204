import "../../index.css"
const bootstrap = require('bootstrap')

/**
 * 
 * @param {} props 
 * props.title
 * props.subTitle
 * props.body
* @returns 
 */
function DestinyDialog(props) { 

    return(
        <div className="mx-5" style={{minWidth:"400px"}}>
            {/* Header */}
            <div className="text-start px-5" style={{minHeight:"12vh", backgroundColor:"rgba(255,255,255,.2)", borderBottom:"solid", borderBottomColor: "grey", paddingTop:"2vh"}}>
                {/* large screens */}
                <h2 className="d-none d-md-block d-large-header py-0 my-0" style={{fontSize:"45px"}}>{props.title}</h2>
                <h3 className="d-none d-md-block d-sub-title py-0 my-2">{props.subTitle}</h3>
                {/* small screens */}
                <h2 className="d-block d-md-none d-large-header-sm py-0 my-0">{props.title}</h2>
                <h3 className="d-block d-md-none d-sub-title-sm py-0 my-2">{props.subTitle}</h3>
            </div>
            {/* Accent bar */}
            <div style={{height:"1vh", width:"50%", backgroundColor:"grey"}}></div>
            {/* Body large screens*/}
            <div className="d-none d-md-block text-start d-description px-5 py-5"  style={{borderBottomStyle:"double", borderBottomColor:"grey"}}>
                {props.body}
            </div>
            {/* Body small screens*/}
            <div className="d-block d-md-none text-start d-description-sm px-5 py-4"  style={{borderBottomStyle:"double", borderBottomColor:"grey"}}>
                {props.body}
            </div>

        </div>
    );
}
export default DestinyDialog;