import "../../index.css"
const bootstrap = require('bootstrap')

/**
 * 
 * @param {} props 
 * props.title
 * props.subTitle
 * props.body
* @returns 
 */
function TestimonialCard(props) { 

    return(
        <div className="mx-5" style={{minWidth:"400px", width: "30vw"}}>
            {/* Body large screens*/}
            <div className="d-none d-md-block text-start d-description px-5 py-5"  style={{minHeight:"12vh", backgroundColor:"rgba(255,255,255,.2)", paddingTop:"2vh"}}>
                {props.body}
            </div>
            {/* Body small screens*/}
            <div className="d-block d-md-none text-start d-description-sm px-5 py-4"  style={{minHeight:"12vh", backgroundColor:"rgba(255,255,255,.2)", paddingTop:"2vh"}}>
                {props.body}
            </div>

        </div>
    );
}
export default TestimonialCard;