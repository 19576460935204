import ExperienceData from "../../Data/Experience";
import BookData from "../../Data/Books";
import TestimonialData from "../../Data/Testimonials";


function DestinyInventoryModal(props) { 
    // props.inventoryType - Book / Testimonial / Work Experience
    // props.data
    // props.id
    // props.dataKey

    const EXPERIENCEDATA = ExperienceData[props.dataKey]
    const BOOKDATA = BookData[props.dataKey]
    const TESTIMONIALDATA = TestimonialData[props.dataKey]

    return(
        <div className="modal fade" id={props.id} tabindex="-1" aria-hidden="true">

        {props.inventoryType === "experience" && 
            <div className=" modal-dialog modal-dialog-scrollable">
            <div className="modal-content" style={{borderRadius: "0px"}}>
            <div className="destiny modal-body p-0">
            <div className='row w-100 m-0' style={{height:"100px", backgroundColor:"#794299", borderTopStyle:"solid", borderTopWidth:"3px", borderTopColor:"wheat"}}>
                <h1 className='d-large-header pt-1 align-self-center mb-1' style={{textTransform: "uppercase"}}>{EXPERIENCEDATA.Title}</h1>
                <h3 style={{color: "lightgrey", fontWeight: "normal", fontSize: "20px"}}>Cayuse Holdings</h3>
                </div>  
                {/* Title Icon */}
                <div className='row py-3 m-0 w-100' style={{borderBottomStyle:"solid", borderBottomWidth:"2px", borderBottomColor: "rgba(255,255,255,.5",  backgroundColor:"rgba(0,0,0,.8)"}}>
                <h1 className='d-sub-text mb-3'><span className='bi bi-bullseye' aria-hidden="true" style={{ color:"#794299"}}> </span>{EXPERIENCEDATA.TimeSpent} Years Experience</h1>
                <h1 className='d-description' style={{ color:"white"}}>{EXPERIENCEDATA.Description}</h1>
                </div>
                {/* Skills */}
                <div className='row w-100 m-0' style={{ backgroundColor:"rgba(0,0,0,.8)"}}>
                <div className='row w-100 m-0 p-4'>
                    <div className='col-6'>
                    {/* https://stackoverflow.com/questions/69080597/×-typeerror-cannot-read-properties-of-undefined-reading-map */}
                    {EXPERIENCEDATA.Skills?.map((skill, index) => {
                        if(index % 2 === 0)
                        {
                            return(
                                <div>
                                    <h3 className='d-general'>{skill[0]}</h3>
                                    <div className="progress destiny-progress mb-3" style={{height: "5px"}}>
                                    <div className="progress-bar bg-light" role="progressbar" style={{width: skill[1] + "%"}} aria-valuenow={skill[1]} aria-valuemin="0" aria-valuemax="100">{skill[1]}%</div>
                                    </div>
                                </div>
                            );
                        } else 
                        {
                            return;
                        }
                    })}
                    </div>
                    <div className='col-6'>
                    {/* https://stackoverflow.com/questions/69080597/×-typeerror-cannot-read-properties-of-undefined-reading-map */}
                    {EXPERIENCEDATA.Skills?.map((skill, index) => {
                        if(index % 2 !== 0)
                        {
                            return(
                                <div>
                                    <h3 className='d-general'>{skill[0]}</h3>
                                    <div className="progress destiny-progress mb-3" style={{height: "5px"}}>
                                    <div className="progress-bar bg-light" role="progressbar" style={{width: skill[1] + "%"}} aria-valuenow={skill[1]} aria-valuemin="0" aria-valuemax="100">{skill[1]}%</div>
                                    </div>
                                </div>
                            );
                        } else 
                        {
                            return;
                        }
                    })}
                    </div>
                </div>
                </div>
                <div className="row m-0 w-100 p-3"  style={{ backgroundColor:"rgba(52, 49, 45, .9)"}}>
            {/* https://stackoverflow.com/questions/69080597/×-typeerror-cannot-read-properties-of-undefined-reading-map */}
            {/* Achievements  */}
                {EXPERIENCEDATA.Achievements?.map((achievement, index) => {
                    return(
                        <div className="row mb-1">
                        
                        <h3 className='col d-general' key={achievement + " " + index} style={{lineHeight: "175%", fontStyle:"italic"}}><span><i className="col-1 d-general bi bi-check-circle-fill me-2"></i></span>{achievement}</h3>
                        </div>
                        
                    );
                })}
            </div>
            </div>
            <div className="destiny book modal-footer" style={{borderTopColor: "transparent", borderRadius: "0px"}}>
                <button type="button" className="btn d-general " data-bs-dismiss="modal"><span className="bi bi-mouse2-fill d-general me-2" style={{ color:"white", fontSize:"20px"}}></span>Close</button>
            </div>
            </div>
            </div>
        }
        {props.inventoryType === "book" && 
            <div className=" modal-dialog modal-dialog-centered" >
            <div className="modal-content" style={{borderRadius: "0px"}}>
            <div className="destiny modal-body p-0">
            <div className='row w-100 m-0' style={{minHeight:"100px", backgroundColor:"#585858", borderTopStyle:"solid", borderTopWidth:"3px", borderTopColor:"wheat"}}>
                <h1 className='d-large-header pt-1 align-self-center mb-1' style={{textTransform: "uppercase"}}>{BOOKDATA.Title}</h1>
                </div>  
                {/* Title Icon */}
                <div className='row pt-3 pb-1 m-0 w-100' style={{borderBottomStyle:"solid", borderBottomWidth:"2px", borderBottomColor: "rgba(255,255,255,.5",  backgroundColor: "#292927"}}>
                <h1 className='d-description' style={{ color:"white"}}>{BOOKDATA.Description}</h1>
                </div>
                {/* Skills */}
            </div>
            <div className="destiny book modal-footer" style={{borderTopColor: "transparent", borderRadius: "0px"}}>
                <button type="button" className="btn d-general " data-bs-dismiss="modal"><span className="bi bi-mouse2-fill d-general me-2" style={{ color:"white", fontSize:"20px"}}></span>Close</button>
            </div>
            </div>
            </div>
        }

        {props.inventoryType === "testimonial" && 
            <div className=" modal-dialog modal-dialog-centered" >
            <div className="modal-content" style={{borderRadius: "0px"}}>
            <div className="destiny modal-body p-0">
                <div className='row w-100 m-0' style={{minHeight:"100px", backgroundColor:"#585858"}}>
                    <h1 className='d-large-header pt-1 align-self-center mb-1' style={{textTransform: "uppercase"}}>{TESTIMONIALDATA.Title}</h1>
                    <h3 style={{color: "lightgrey", fontWeight: "normal", fontSize: "20px"}}>{TESTIMONIALDATA.Author}</h3>
                </div>  
                {/* Title Icon */}
                <div className='row py-3 m-0 w-100' style={{borderTopStyle:"solid", borderTopWidth:"2px", borderTopColor: "rgba(255,255,255,.5",  backgroundColor: "#585858"}}>
                    <h1 className='d-description' style={{ color:"white"}}>{TESTIMONIALDATA.Quote}</h1>
                </div>
            {/* Skills */}
            </div>
            <div className="destiny book modal-footer" style={{borderTopColor: "transparent", borderRadius: "0px"}}>
                <button type="button" className="btn d-general " data-bs-dismiss="modal"><span className="bi bi-mouse2-fill d-general me-2" style={{ color:"white", fontSize:"20px"}}></span>Close</button>
            </div>
            </div>
            </div>
        }
        
        </div>
    )
    
}

export default DestinyInventoryModal