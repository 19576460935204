import { useEffect } from "react";
import DestinyInventoryItem from "../Components/DestinyInventoryItem"
/**
 * 
 * @param {} props 
    props.inventoryItems: List of elements to be included in inventory page
    props.id
    props.parent
    props.title
* @returns 
 */

function DestinyInventoryPage(props) { 
    const numberOfItems = 15;
    const numberOfPages = 1;

    const emptyInventory = <DestinyInventoryItem empty="true" quantity="0"></DestinyInventoryItem>
    let inventoryItemsList = [];
    for(let i = 0; i < numberOfItems; i++)
        {
            if (i >= props.inventoryItems.length)
            {
                inventoryItemsList.push(emptyInventory)
            } else 
            {
                inventoryItemsList.push(props.inventoryItems[i])
            }
        }

    return(
        // Set Row-Cols to one greater than you actually need to account for the margin. Row-cols-6 displays 5 columns per row
        <div className={'accordion-collapse collapse ' + props.show} id={props.id} data-bs-parent={props.parent}>
            <div className="mb-3" style={{color:"lightgray", borderBottomColor:"lightgray", borderBottomStyle:"solid", borderBottomWidth: "1px", textTransform:"uppercase"}}>
            {props.pageTitle}
            </div>
            <div className="row row-cols-5 g-2">
                    {inventoryItemsList}
            </div>
        </div>
        
        

    );

}

export default DestinyInventoryPage;