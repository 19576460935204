import NPC from "../Sprites/NPC"
import { AnimatedSprite, Assets, Sprite, Texture} from "pixi.js";
import LogData from "../../../../Images/Sprites/Log JSON.json"
import LogSS from "../../../../Images/Sprites/Log Spritesheet.png"
import {parseSpritesheet} from "../../../../Pages/Components/Game/Utils"
import TextureEnum from "../Textures";
class LogNPC extends NPC
{
    constructor(dialog)
    {
        super([Texture.WHITE], dialog);
        this.setTextures().then(() =>
        {
            this.textures = this.standingDown;
            this.anchor = (0.5,0.5)
            this.animationSpeed = .1;
            // TODO change the scale to 1. Collision logic will need to be updated
            // console.log(`The height of the Log Sprite : ${this.height}`)
            
        })
    }

    async setTextures()
    {
        // Sprite Textures
        Assets.add({alias: "LogNPC", src: LogSS});
        const spritePromise  = Assets.load(["LogNPC"]);

        await spritePromise.then(async () => {
            await parseSpritesheet(LogSS, LogData).then((value) => {
                this.LogTextures=value
    
                this.walkUpTextures = [value["log_walking_up_1.png"], value["log_walking_up_2.png"]];
                this.walkDownTextures = [value["log_walking_down_1.png"], value["log_walking_down_2.png"]];
                this.walkLeftTextures = [value["log_walking_left_1.png"], value["log_walking_left_2.png"]];
                this.walkRightTextures = [value["log_walking_right_1.png"], value["log_walking_right_2.png"]];
                this.sleepingTextures = [value["log_sleeping_1.png"], value["log_sleeping_2.png"],value["log_sleeping_3.png"],value["log_sleeping_4.png"]];
                this.wakingUpTextures = [value["log_waking_up_1.png"], value["log_waking_up_2.png"], value["log_waking_up_3.png"]];
    
                this.standingUp = [value["log_standing_up.png"]];
                this.standingDown = [value["log_standing_down.png"]];
                this.standingLeft = [value["log_standing_left.png"]];
                this.standingRight = [value["log_standing_right.png"]];
            })
        })
        
        
    }

    sleep()
    {
        this.textures = this.sleepingTextures;
        this.animationSpeed = .1;   
        this.play();
    }

    wakeUp()
    {
        this.stop()
        this.loop = false;
        this.textures = this.wakingUpTextures;
        this.play();
        this.onComplete = () => {
            this.textures = this.standingDown;
            this.loop = true;
            this.play();
            this.onComplete = () => {
            };
        };
    }

    interact()
    {
        super.interact();

    }

    highlight()
    {
        this.interactTextSprite.visible = true;
        this.interactTextSprite.play()
    }

    stopHighlight()
    {
        this.interactTextSprite.visible = false;
        this.interactTextSprite.stop()
    }

    async addHighlightBoxToSprite()
    {
        // TODO for some reason, when sprites are 32px, the center is 0 and when the sprite is 16px the center is -8. This is the current workaround but I need to determine why it's the case.
        var dotChatTexture = TextureEnum.getAllTextures().OVERWORLD_TEXTURES;
        console.log(dotChatTexture)
        this.interactTextSprite = new AnimatedSprite([dotChatTexture["1x1_chat_bubble_one_period.png"], dotChatTexture["1x1_chat_bubble_two_period.png"], dotChatTexture["1x1_chat_bubble_three_period.png"]])
        this.interactTextSprite.visible = false;
        this.interactTextSprite.stop()
        this.interactTextSprite.animationSpeed = .05;
        this.interactTextSprite.scale = (1.5,1.5)
        this.interactTextSprite.x = -8;
        this.interactTextSprite.y = -32
        this.InteractTextStartingY = this.interactTextSprite.y
        this.InteractTextStartinX = this.interactTextSprite.x
        // this.interactText.hide()
        this.addChild(this.interactTextSprite)

    }

}

export default LogNPC