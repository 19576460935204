import "../../index.css"
const bootstrap = require('bootstrap')

/**
 * 
 * @param {} props 
    props.dataBsTarget: If the item controlls a collapsible element, provide the ID of the element.
    props.ariaExpanded: If the item controlls a collapsible element, true or false if the element should be collapsed.
    props.dataBsToggle: If the item controlls a collapsible element, Collapse if it should collapse an element.
    props.title
* @returns 
 */
function SelectableSquare(props) { 
     console.log("dataBsTarget:" + props.dataBsTarget);
     console.log("ariaExpanded:" + props.ariaExpanded);
     console.log("dataBsToggle:" + props.dataBsToggle);
     console.log("title:" + props.title);


    var formattedTitleHeader;
    if(props.title)
    {
        formattedTitleHeader = props.title.replace(" ","-") 
    }

    return(
        <div>
          {/* large Screens */}
          <div className="col-4">
            <button role="button" data-bs-toggle={props.dataBsToggle} className='shadow d-header text-break d-none d-md-block top-margin-5 btn d-flex align-items-center' data-bs-target={"#" +props.dataBsTarget} aria-expanded={props.ariaExpanded} aria-controls={props.dataBsTarget } style={{height:"15vh", width:"15vh", backgroundColor:"#794299", borderStyle:"solid",borderWidth:"medium", borderColor:"white", borderRadius:"0px", color:"white", whiteSpace: "normal"}}>
              <p className="d-header">{props.title}</p>
            </button>
          </div>
           
          {/* small Screens */}
          <div className="col-1">
            <button role="button" data-bs-toggle={props.dataBsToggle} className={'bi ' + (props.ariaExpanded === "false" ? 'bi-circle' : 'bi-circle-fill') + ' mx-auto d-block d-md-none shadow d-header text-break top-margin-5 btn d-flex align-items-center'} data-bs-target={"#" +props.dataBsTarget} aria-expanded={props.ariaExpanded} aria-controls={props.dataBsTarget } style={{ color:"#794299", background: "transparent"}}>
            </button>
          </div>
          
        
        </div>
        

    );
}
export default SelectableSquare;