import React, { useState, useEffect } from 'react';
import Navbar from './Components/Navbar'
import ExperienceCard from './Components/ExperienceCards'
import SelectableSquare from './Components/SelectableSquare'
import DestinyDialog from './Components/DestinyDialog'
import TestimonialCard from './Components/TestimonialCard'
import HorizontalScroll from './Components/HorizontalScroll'
import CODExperience from './Components/CODExperience'
import CODFooter from './Components/CODFooter'
import CODBookCallingCard from './Components/CODBookCallingCards'
import BookData from '../Data/Books'
import TestimonialData from "../Data/Testimonials"
import ExperienceData from "../Data/Experience"
import "bootstrap-icons/font/bootstrap-icons.css";
import destinyEmblem from "../Images/Stars_to_Circuits_Emblem.png"
import Game from "./Components/Game/Game"
import SkillData from "../Data/Skills.js"
import DestinyInventoryPage from "./Components/DestinyInventoryPage.js"
import DestinyInventoryItem from './Components/DestinyInventoryItem.js';
import DestinyInventoryPageSelector from "./Components/DestinyInventoryPageSelector.js"

function HomePage() {
  <link rel="icon" href="%PUBLIC_URL%/Destiny Generic Site.png" />
  

  var [current_theme, setTheme] = useState("default")
  var [selectedSkill, setSelectedSkill] = useState("Developer")
  
  const updateTheme= (new_theme) =>
  {
    setTheme(new_theme);

  }

  const booksInventory = Object.keys(BookData).map((bookKey)  => {
      return <DestinyInventoryItem empty="false" quantity={1} rarity="uncommon" image={BookData[bookKey].Img} inventoryType="book" dataKey={bookKey} id={"Book" + bookKey}></DestinyInventoryItem>})

  const workExperienceInventory = Object.keys(ExperienceData).map((experienceKey)  => {
    return <DestinyInventoryItem empty="false" quantity={ExperienceData[experienceKey].timeSpent} rarity="uncommon" inventoryType="experience" id={"Experience" + experienceKey} dataKey={experienceKey}></DestinyInventoryItem>})

  const testimonialInventory = Object.keys(TestimonialData).map((testimonialKey)  => {
    return <DestinyInventoryItem empty="false" quantity={1} rarity="uncommon" inventoryType="testimonial" dataKey={testimonialKey} id={"Testimonial" + testimonialKey}></DestinyInventoryItem>})

  console.log("Book Data:" + BookData);
  console.log("Testimonial Data:" + TestimonialData);
  console.log("Experience Data:" + ExperienceData);

  return (
    <div>
      {current_theme === "default" ? 
      <div className='default-bg p-0'>
        <Navbar theme="Default" selected_nav="Home"></Navbar>
        {/* Theme Buttons */}
        <div className="dropdown" style={{position: "absolute", top:"15px", right:"50px", width: "20px"}}>
          
          <button className="btn btn-secondary dropdown-toggle dropdown-circle" style={{color:"#0A0B1C", padding:"0", backgroundColor:"greenyellow"}} type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className='bi bi-moon-stars-fill'></i></button>
          <div className="dropdown-menu circle" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item dropdown-circle my-1 float-end" style={{color:"#0A0B1C", backgroundColor:"greenyellow"}} onClick={() => updateTheme("destiny")}>
              <i style={{height: "25px", width:"25px"}}>
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0,0,256,256">
                <g fill="#0A0B1C" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{mixBlendMode: "normal"}}><g transform="scale(5.12,5.12)"><path d="M37.52344,5.95703c-1.16211,0.04004 -2.30273,0.36523 -3.33398,0.95898l-2.19141,1.26953v11.86133c0,3.86 -3.14,7 -7,7c-1.86,0 -3.61922,-0.72102 -4.94922,-2.04102c-1.33,-1.34 -2.05078,-3.09898 -2.05078,-4.95898v-11.86133l-2.18945,-1.25977c-1.65,-0.96 -3.57945,-1.22047 -5.43945,-0.73047c-1.85,0.5 -3.39938,1.69156 -4.35938,3.35156c-0.63,1.09 -0.96094,2.33008 -0.96094,3.58008c0,2.55 1.36984,4.93852 3.58984,6.22852c5.66,3.27 9.17969,9.36039 9.17969,15.90039v1.60938c0,1.92 0.75156,3.72008 2.10156,5.08008c1.32,1.32 3.07945,2.07156 4.93945,2.10156h0.11914c1.82,0 3.56992,-0.73055 4.91992,-2.06055c1.45,-1.42 2.28125,-3.39062 2.28125,-5.39062v-1.33984c0,-3.27 0.87875,-6.42969 2.46875,-9.17969c1.59,-2.75 3.88094,-5.0907 6.71094,-6.7207c1.66,-0.97 2.84961,-2.51914 3.34961,-4.36914c0.49,-1.85 0.2293,-3.77945 -0.7207,-5.43945c-0.96,-1.66 -2.50914,-2.85156 -4.36914,-3.35156c-0.69375,-0.18375 -1.39844,-0.2623 -2.0957,-0.23828zM24.99805,7.04688c-1.64,0 -3.09977,0.78953 -4.00977,2.01953c-0.62,0.83 -0.99023,1.86047 -0.99023,2.98047v8c0,1.38 0.56094,2.62906 1.46094,3.53906c0.91,0.9 2.15906,1.46094 3.53906,1.46094c2.76,0 5,-2.24 5,-5v-8c0,-1.12 -0.36828,-2.15047 -0.98828,-2.98047c-0.91,-1.23 -2.37172,-2.01953 -4.01172,-2.01953z"></path></g></g>
              </svg>
              </i>
              
            </a>
            <a className="dropdown-item dropdown-circle my-1 float-end" style={{color:"#0A0B1C", backgroundColor:"greenyellow"}} onClick={() => updateTheme("callOfDuty")}>
              <i style={{height: "25px", width:"25px"}}>
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0,0,256,256">
                <g fill="#0A0B1C" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{mixBlendMode: "normal"}}><g transform="scale(5.12,5.12)"><path d="M0,16h5v17h-5zM5,16l9.011,8l8.478,-8v7l-8.478,8l-9.011,-8zM45,16.196h5v17h-5zM27.489,33l9.033,-8l8.478,8v-7l-8.478,-8l-9.033,8zM27.489,21.005v-5.005h-5v9.994zM22.489,28.016v4.984h5v-9.973z"></path></g></g>
                </svg>
              </i>
            </a>
          </div>
        </div>
      
        <div className='container'>
          <div className='row pt-4'>
            <div className='col-12 default-subheader'>
            <div className='col'>
              <div className='row'>
                <h1 className='default-subheader'>
                  About
                </h1>
              <div className='row'>
                <div className='green-line'></div>
              </div>
              <div className='row pt-4'>
                <p className='default-base-text'>
                  Welcome to my work in progress Portfolio website! <br></br> <br></br>

                  I wanted merge my interests between gaming and development and created a few different ways of displaying my 
                  portfolio information.<br></br> <br></br>

                  There is: <br></br>
                  - Destiny 2 inspired theme <br></br>
                  - Call of Duty Warzone inspired theme <br></br>
                  - And an interactive game that is currently still being developed.<br></br> <br></br>

                  Everything is still under development but I am excited to show the progress!
                </p>
              </div>
              </div>
            </div>
            </div> 
            {/* <div className='col-6'>
              <Game></Game>
            </div>  */}
          </div>
        </div>

        <div className='container pt-5'>
          <div className='row g-3'>
            <div className='col-6 col-md-4 col-lg-3'> 
              <div className='project-card  d-flex flex-column align-items-center justify-content-center'>
                <div className='number'>6</div>
                <div className='header'>Data Analyst Projects</div>
              </div>
            </div>
            <div className='col-6 col-md-4 col-lg-3'>
              <div className='project-card  d-flex flex-column align-items-center justify-content-center'> 
                <div className='number'>3</div>
                <div className='header'>Web Development Projects</div>
              </div>
            </div>
            <div className='col-6 col-md-4 col-lg-3'> 
              <div className='project-card  d-flex flex-column align-items-center justify-content-center'>
                <div className='number'>2</div>
                <div className='header'>AWS Certifications</div>
              </div>
            </div>
            <div className='col-6 col-md-4 col-lg-3'> 
              <div className='project-card  d-flex flex-column align-items-center justify-content-center'>
                <div className='number'>2</div>
                <div className='header'>Projects Served</div>
              </div>
            </div>
          </div>
        </div>

        <div className='container pt-5'>
          <div className='col pb-3'>
            <div className='row'>
              <h1 className='default-subheader'>
                Skills
              </h1>
            </div>
            <div className='row'>
              <div className='green-line'></div>
            </div>
          </div>
          <div className='row pt-4 d-none d-md-flex'>
            <div className='col-4'> 
              <h3 className='default-description-header text-center'>Developer Skills</h3>
              {Object.entries(SkillData.SkillData).map(skill => 
                {
                  if(skill[1]["Category"] === "Development")
                  {
                    return <div>
                      <label className='skill-label'>{skill[1]["Label Name"]}</label>
                      <div className="progress bg-secondary mb-3" style={{height: "5px"}}>
                        <div className="progress-bar bg-light" role="progressbar" style={{width: skill[1]["ExperiencePCT"] + "%"}} aria-valuenow={skill[1]["ExperiencePCT"]} aria-valuemin="0" aria-valuemax="100">{skill[1]["ExperiencePCT"]}%</div>
                      </div>
                    </div>
                  }
                }
              )}
              
            </div>
            <div className='col-4'> 
              <h3 className='default-description-header text-center'>Data Analyst Skills</h3>
              {Object.entries(SkillData.SkillData).map(skill => 
                {
                  if(skill[1]["Category"] === "Data Analyst")
                  {
                    return <div>
                      <label className='skill-label'>{skill[1]["Label Name"]}</label>
                      <div className="progress bg-secondary mb-3" style={{height: "5px"}}>
                        <div className="progress-bar bg-light" role="progressbar" style={{width: skill[1]["ExperiencePCT"] + "%"}} aria-valuenow={skill[1]["ExperiencePCT"]} aria-valuemin="0" aria-valuemax="100">{skill[1]["ExperiencePCT"]}%</div>
                      </div>
                    </div>
                  }
                }
              )}
            </div>
            <div className='col-4'> 
              <h3 className='default-description-header text-center'>Miscellaneous Skills</h3>
              {Object.entries(SkillData.SkillData).map(skill => 
                {
                  if(skill[1]["Category"] === "Miscellaneous")
                  {
                    return <div>
                      <label className='skill-label'>{skill[1]["Label Name"]}</label>
                      <div className="progress bg-secondary mb-3" style={{height: "5px"}}>
                        <div className="progress-bar bg-light" role="progressbar" style={{width: skill[1]["ExperiencePCT"] + "%"}} aria-valuenow={skill[1]["ExperiencePCT"]} aria-valuemin="0" aria-valuemax="100">{skill[1]["ExperiencePCT"]}%</div>
                      </div>
                    </div>
                  }
                }
              )}
            </div>
          </div>

          <div className='row pt-4 d-block d-md-none'>
            <div className="btn-group mb-5" role="group" aria-label="Basic radio toggle button group">
              <input type="radio" className="btn-check skill-radio-button-input " name="btnradio" id="btnradio1" autoComplete="off" defaultChecked onClick={()=> {
                setSelectedSkill("Developer")
              }}/>
              <label className={"btn default-description-header text-center skill-radio-button "  + (selectedSkill === "Developer" ? " checked" :"")} htmlFor="btnradio1">Developer Skills</label>

              <input type="radio" className="btn-check skill-radio-button-input " name="btnradio" id="btnradio2" autoComplete="off" onClick={()=>{
                setSelectedSkill("Data Analyst")
              }}/>
              <label className={"btn default-description-header text-center skill-radio-button"  + (selectedSkill === "Data Analyst" ? " checked" :"")} htmlFor="btnradio2">Data Analyst Skills</label>

              <input type="radio" className="btn-check skill-radio-button-input " name="btnradio" id="btnradio3" autoComplete="off" onClick={()=>{
                setSelectedSkill("Miscellaneous")
              }}/>
              <label className={"btn default-description-header text-center skill-radio-button" + (selectedSkill === "Miscellaneous" ? " checked" :"")} htmlFor="btnradio3">Misc. Skills</label>
            </div>
          
          {selectedSkill === "Developer" && <div className='col-12'> 
              {Object.entries(SkillData.SkillData).map(skill => 
                {
                  if(skill[1]["Category"] === "Development")
                  {
                    return <div>
                      <label className='skill-label'>{skill[1]["Label Name"]}</label>
                      <div className="progress bg-secondary mb-3" style={{height: "5px"}}>
                        <div className="progress-bar bg-light" role="progressbar" style={{width: skill[1]["ExperiencePCT"] + "%"}} aria-valuenow={skill[1]["ExperiencePCT"]} aria-valuemin="0" aria-valuemax="100">{skill[1]["ExperiencePCT"]}%</div>
                      </div>
                    </div>
                  }
                }
              )}
              
            </div>}

            {selectedSkill === "Data Analyst" && <div className='col-12'> 
              
              {Object.entries(SkillData.SkillData).map(skill => 
                {
                  if(skill[1]["Category"] === "Data Analyst")
                  {
                    return <div>
                      <label className='skill-label'>{skill[1]["Label Name"]}</label>
                      <div className="progress bg-secondary mb-3" style={{height: "5px"}}>
                        <div className="progress-bar bg-light" role="progressbar" style={{width: skill[1]["ExperiencePCT"] + "%"}} aria-valuenow={skill[1]["ExperiencePCT"]} aria-valuemin="0" aria-valuemax="100">{skill[1]["ExperiencePCT"]}%</div>
                      </div>
                    </div>
                  }
                }
              )}
            </div>
            }

            {selectedSkill === "Miscellaneous" && <div className='col-12'> 
              
              {Object.entries(SkillData.SkillData).map(skill => 
                {
                  if(skill[1]["Category"] === "Miscellaneous")
                  {
                    return <div>
                      <label className='skill-label'>{skill[1]["Label Name"]}</label>
                      <div className="progress bg-secondary mb-3" style={{height: "5px"}}>
                        <div className="progress-bar bg-light" role="progressbar" style={{width: skill[1]["ExperiencePCT"] + "%"}} aria-valuenow={skill[1]["ExperiencePCT"]} aria-valuemin="0" aria-valuemax="100">{skill[1]["ExperiencePCT"]}%</div>
                      </div>
                    </div>
                  }
                }
              )}
            </div>}
          </div>
        </div>

        <div className='container pt-5'>
          <div className='col pb-3'>
            <div className='row'>
              <h1 className='default-subheader'>
                Interests
              </h1>
            </div>
            <div className='row'>
              <div className='green-line'></div>
            </div>
          </div>
          <div  className='d-grid gap-4 pt-4'>
            <div className='row row-cols-3 row-cols-md-4 row-cols-lg-6 g-3 d-flex justify-content-evenly'>
              <div className='col'>
                <div className='default-rounded text-center'>
                  Gaming
                </div>
              </div>  
              <div className='col'>
                <div className='default-rounded text-center'>
                  Web Development
                </div>
              </div>  
              <div className='col'>
                <div className='default-rounded text-center'>
                  Video Game Development
                </div>
              </div>  
              <div className='col'>
                <div className='default-rounded text-center'>
                  Legend of Zelda
                </div>
              </div>  
              <div className='col'>
                <div className='default-rounded text-center'>
                  Call of Duty
                </div>
              </div>  
              <div className='col'>
                <div className='default-rounded text-center'>
                  Horror Movies
                </div>
              </div>  
              <div className='col'>
                <div className='default-rounded text-center'>
                  Camping
                </div>
              </div>                   
            </div>
          </div>
        </div>

        <div className='container pt-5'>
          <div className='col pb-3'>
            <div className='row'>
              <h1 className='default-subheader'>
                Testimonials
              </h1>
            </div>
            <div className='row'>
              <div className='green-line'></div>
            </div>
          </div>
        </div>
        <div className='container-fluid pt-4'>
        <HorizontalScroll array={[<TestimonialCard title={TestimonialData["PositiveAttitude"].Title} subTitle={TestimonialData["PositiveAttitude"].Author} body={TestimonialData["PositiveAttitude"].Quote}></TestimonialCard>,
          <TestimonialCard title={TestimonialData["Organization"].Title} subTitle={TestimonialData["Organization"].Author} body={TestimonialData["Organization"].Quote}></TestimonialCard>,
          <TestimonialCard title={TestimonialData["Persistence"].Title} subTitle={TestimonialData["Persistence"].Author} body={TestimonialData["Persistence"].Quote}></TestimonialCard>,
          <TestimonialCard title={TestimonialData["DetailOriented"].Title} subTitle={TestimonialData["DetailOriented"].Author} body={TestimonialData["DetailOriented"].Quote}></TestimonialCard>]}></HorizontalScroll>
        </div>
    </div>:null}

    {current_theme === "destiny" ? 
    <div className='destiny-bg'>
      
      

      <div className='container-fluid' style={{height: "fit-content", background: "url("+ destinyEmblem +")", backgroundPosition:"50% 80%",backgroundSize: "cover", backgroundRepeat:"no-repeat", padding:"0px"}}>
      <Navbar theme="Destiny" selected_nav="Home"></Navbar>
        {/* Theme Buttons */}
        <div className="dropdown" style={{position: "absolute", top:"15px", right:"50px", width: "20px"}}>
          
          <button className="btn btn-secondary dropdown-toggle dropdown-circle" style={{color:"#020817", backgroundColor:"white"}} type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i style={{height: "25px", width:"25px"}}>
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0,0,256,256">
                <g fill="#020817" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{mixBlendMode: "normal"}}><g transform="scale(5.12,5.12)"><path d="M37.52344,5.95703c-1.16211,0.04004 -2.30273,0.36523 -3.33398,0.95898l-2.19141,1.26953v11.86133c0,3.86 -3.14,7 -7,7c-1.86,0 -3.61922,-0.72102 -4.94922,-2.04102c-1.33,-1.34 -2.05078,-3.09898 -2.05078,-4.95898v-11.86133l-2.18945,-1.25977c-1.65,-0.96 -3.57945,-1.22047 -5.43945,-0.73047c-1.85,0.5 -3.39938,1.69156 -4.35938,3.35156c-0.63,1.09 -0.96094,2.33008 -0.96094,3.58008c0,2.55 1.36984,4.93852 3.58984,6.22852c5.66,3.27 9.17969,9.36039 9.17969,15.90039v1.60938c0,1.92 0.75156,3.72008 2.10156,5.08008c1.32,1.32 3.07945,2.07156 4.93945,2.10156h0.11914c1.82,0 3.56992,-0.73055 4.91992,-2.06055c1.45,-1.42 2.28125,-3.39062 2.28125,-5.39062v-1.33984c0,-3.27 0.87875,-6.42969 2.46875,-9.17969c1.59,-2.75 3.88094,-5.0907 6.71094,-6.7207c1.66,-0.97 2.84961,-2.51914 3.34961,-4.36914c0.49,-1.85 0.2293,-3.77945 -0.7207,-5.43945c-0.96,-1.66 -2.50914,-2.85156 -4.36914,-3.35156c-0.69375,-0.18375 -1.39844,-0.2623 -2.0957,-0.23828zM24.99805,7.04688c-1.64,0 -3.09977,0.78953 -4.00977,2.01953c-0.62,0.83 -0.99023,1.86047 -0.99023,2.98047v8c0,1.38 0.56094,2.62906 1.46094,3.53906c0.91,0.9 2.15906,1.46094 3.53906,1.46094c2.76,0 5,-2.24 5,-5v-8c0,-1.12 -0.36828,-2.15047 -0.98828,-2.98047c-0.91,-1.23 -2.37172,-2.01953 -4.01172,-2.01953z"></path></g></g>
              </svg>
            </i>
          </button>
          <div className="dropdown-menu dropdown-menu-end circle" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item dropdown-circle bi bi-moon-stars-fill my-1 float-end" style={{color:"#020817", backgroundColor:"white"}}  onClick={()=> updateTheme("default")} ></a>
            <a className="dropdown-item dropdown-circle my-1 float-end" style={{color:"#020817", backgroundColor:"white"}} onClick={() => updateTheme("callOfDuty")}>
              <i style={{height: "25px", width:"25px"}}>
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0,0,256,256">
                <g fill="#0A0B1C" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{mixBlendMode: "normal"}}><g transform="scale(5.12,5.12)"><path d="M0,16h5v17h-5zM5,16l9.011,8l8.478,-8v7l-8.478,8l-9.011,-8zM45,16.196h5v17h-5zM27.489,33l9.033,-8l8.478,8v-7l-8.478,-8l-9.033,8zM27.489,21.005v-5.005h-5v9.994zM22.489,28.016v4.984h5v-9.973z"></path></g></g>
                </svg>
              </i>
            </a>
          </div>
        </div>
        <div className='row d-flex align-items-start'>
          <div className='col-1'/>
          <div className='col-1 align-self-center bi bi-stars' style={{color: "white", fontSize: "clamp(24px,9.5vw,64px)", margin: "20px", padding: "0px"}}></div>
          <div className='col-7'>
            <div className='row'><h1 className='d-h1'>Kirsten Saling</h1></div>
            <div className='row'><h1 className='d-title-description'>// Data Analyst & Web Developer</h1></div>
          </div>
        </div>
      </div>
      <div className='container-fluid top-margin-5 mb-5'>
          <div className='container-fluid row accordion justify-content-center' id="Experience">
            {/* Selectable Tiles */}
            <div className='top-margin-5 col-1 px-0'>
                <DestinyInventoryPageSelector icon="bi-code" dataBsToggle="collapse" dataBsTarget="WorkExperienceInventory" ariaExpanded="true"></DestinyInventoryPageSelector>
                <DestinyInventoryPageSelector icon="bi-bug" dataBsToggle="collapse" dataBsTarget="BooksInventory" ariaExpanded="false"></DestinyInventoryPageSelector>
                <DestinyInventoryPageSelector icon="bi-card-text" dataBsToggle="collapse" dataBsTarget="TestimonialInventory"></DestinyInventoryPageSelector>
            </div>
            <div className='px-5 top-margin-10 col-10 col-md-6'>
              <div className='row accordion-group d-flex flex-column align-items-start justify-content-start'>
              
                <DestinyInventoryPage id="WorkExperienceInventory" parent="#Experience" show="show" inventoryItems={workExperienceInventory} pageTitle="Work Experience"></DestinyInventoryPage>

                <DestinyInventoryPage id="BooksInventory" parent="#Experience" show="" inventoryItems={booksInventory} pageTitle="Books"></DestinyInventoryPage>


                <DestinyInventoryPage id="TestimonialInventory" parent="#Experience" show="" inventoryItems={testimonialInventory} pageTitle="Testimonials"></DestinyInventoryPage>
              </div>
            </div>
          </div>
      </div>
    </div>
    :null}
    
    {current_theme === "callOfDuty" ? 
    <div>
      <div className='cod-bg-img-container'>
        <div className='cod-bg-img'></div>
      </div>
    <div className='cod-bg'>
      <Navbar theme="COD" selected_nav="Home"></Navbar>
        <div style={{height: "1px", background:"linear-gradient(to right, rgba(68, 153, 186,1) 35%, rgba(20,17,15,.5) 50%) "}}></div>
        {/* Theme Buttons */}
        <div className="dropdown" style={{position: "absolute", top:"15px", right:"50px", width: "20px"}}>
          <button className="btn btn-secondary dropdown-toggle dropdown-circle" style={{color:"#bad7de", backgroundColor:"#325667", border: "solid 2px", borderColor: "#bad7de"}} type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i style={{height: "25px", width:"25px"}}>
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0,0,256,256">
                <g fill="#bad7de" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{mixBlendMode: "normal"}}><g transform="scale(5.12,5.12)"><path d="M0,16h5v17h-5zM5,16l9.011,8l8.478,-8v7l-8.478,8l-9.011,-8zM45,16.196h5v17h-5zM27.489,33l9.033,-8l8.478,8v-7l-8.478,-8l-9.033,8zM27.489,21.005v-5.005h-5v9.994zM22.489,28.016v4.984h5v-9.973z"></path></g></g>
              </svg>
            </i>
          </button>
          <div className="dropdown-menu circle" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item dropdown-circle bi bi-moon-stars-fill my-1 float-end" style={{color:"#bad7de", backgroundColor:"#325667", border: "solid 2px", borderColor: "#82d3f4" }}  onClick={()=> updateTheme("default")} ></a>
            <a className="dropdown-item dropdown-circle my-1 float-end" style={{color:"#bad7de", backgroundColor:"#325667", border: "solid 2px", borderColor: "#82d3f4"}} onClick={() => updateTheme("destiny")}>
              <i style={{height: "25px", width:"25px"}}>
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0,0,256,256">
                <g fill="#bad7de" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{mixBlendMode: "normal"}}><g transform="scale(5.12,5.12)"><path d="M37.52344,5.95703c-1.16211,0.04004 -2.30273,0.36523 -3.33398,0.95898l-2.19141,1.26953v11.86133c0,3.86 -3.14,7 -7,7c-1.86,0 -3.61922,-0.72102 -4.94922,-2.04102c-1.33,-1.34 -2.05078,-3.09898 -2.05078,-4.95898v-11.86133l-2.18945,-1.25977c-1.65,-0.96 -3.57945,-1.22047 -5.43945,-0.73047c-1.85,0.5 -3.39938,1.69156 -4.35938,3.35156c-0.63,1.09 -0.96094,2.33008 -0.96094,3.58008c0,2.55 1.36984,4.93852 3.58984,6.22852c5.66,3.27 9.17969,9.36039 9.17969,15.90039v1.60938c0,1.92 0.75156,3.72008 2.10156,5.08008c1.32,1.32 3.07945,2.07156 4.93945,2.10156h0.11914c1.82,0 3.56992,-0.73055 4.91992,-2.06055c1.45,-1.42 2.28125,-3.39062 2.28125,-5.39062v-1.33984c0,-3.27 0.87875,-6.42969 2.46875,-9.17969c1.59,-2.75 3.88094,-5.0907 6.71094,-6.7207c1.66,-0.97 2.84961,-2.51914 3.34961,-4.36914c0.49,-1.85 0.2293,-3.77945 -0.7207,-5.43945c-0.96,-1.66 -2.50914,-2.85156 -4.36914,-3.35156c-0.69375,-0.18375 -1.39844,-0.2623 -2.0957,-0.23828zM24.99805,7.04688c-1.64,0 -3.09977,0.78953 -4.00977,2.01953c-0.62,0.83 -0.99023,1.86047 -0.99023,2.98047v8c0,1.38 0.56094,2.62906 1.46094,3.53906c0.91,0.9 2.15906,1.46094 3.53906,1.46094c2.76,0 5,-2.24 5,-5v-8c0,-1.12 -0.36828,-2.15047 -0.98828,-2.98047c-0.91,-1.23 -2.37172,-2.01953 -4.01172,-2.01953z"></path></g></g>
              </svg>
              </i>
              
            </a>
          </div>
        </div>
      <div>
      <div>

      <ul className="cod nav nav-tabs py-2 px-3" id="expTab" role="tablist">
        <li className="nav-item">
          <a className="cod nav-link active" id="exp-tab" data-bs-toggle="tab" href="#exp" data-bs-target="#exp" role="tab" aria-controls="exp" type="button" aria-selected="true">Experience</a>
        </li>
        <li className="nav-item">
          <a className="cod nav-link" id="books-tab" data-bs-toggle="tab" href="#books" data-bs-target="#books" role="tab" type="button" aria-controls="books" aria-selected="false">Books</a>
        </li>
      </ul>
      </div>
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane active show" id="exp" role="tabpanel" aria-labelledby="exp-tab" tabIndex="0"style={{height:"80vh"}}>
          <CODExperience></CODExperience>
          </div>
          
        <div className="tab-pane" id="books" role="tabpanel" aria-labelledby="books-tab" tabIndex="0" style={{height:"94vh"}}><CODBookCallingCard></CODBookCallingCard></div>
      </div>
      </div>
      {/* Footer */}
      </div>

      <CODFooter></CODFooter>
    </div>:null}

    
    
    </div>
  );
}

export default HomePage;