import Interactable from './Interactable';
class Sign extends Interactable 
{
    constructor(texture, textArray, textBox = undefined)
    {
        super(texture, textArray)
        this.textArray = textArray
        this.currentDialogStep = 0;
        this.dialogText = textBox;
        console.log("Dialog Text")
        console.log(this.dialogText)
    }

    interact()
    {
        super.interact()
        this.stopHighlight()
        
        let interactionComplete = false;
        if (this.isInteracting && this.textArray)
        {
            console.log("Sign is Interacting")
            if(this.currentDialogStep === this.textArray.length)
            {
                console.log("Sign is at last step")
                this.isInteracting = false;
                this.dialogText.hide();
                this.currentDialogStep = 0;
                this.dialogText.setHasMoreText(false)
                interactionComplete = true;
            } else 
            {
                console.log("Sign is showing Text")
                this.dialogText.show();
                console.log(this.textArray[this.currentDialogStep])
                // Checks if it is the last piece of dialog
                if(this.currentDialogStep === this.textArray.length - 1)
                {
                    this.dialogText.setText(this.textArray[this.currentDialogStep], false)
                } else 
                {
                    this.dialogText.setText(this.textArray[this.currentDialogStep], true)
                }
                this.currentDialogStep++
                interactionComplete = false;
            }
        }

        return interactionComplete
    }
// add textbox above object with letter to interact
    highlight()
    {
        super.highlight()
    }

    stopHighlight()
    {
        super.stopHighlight()
    }

}

export default Sign