import { useNavigate } from "react-router-dom"
import "../../index.css"
const bootstrap = require('bootstrap')

function NavBar(props) {
    const navigate = useNavigate()

    
    const changePath = (path) => {
        navigate(path)
    }

    if(props.theme === "Default")
    {
        return (
            <nav className="default navbar navbar-expand-md d-nav m-0 " style={{height: "60px"}}>
                <div className="container-fluid" >
                {/* <a class="navbar-brand" href="#">Navbar</a>  */}
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#defaultNavbar" aria-controls="defaultNavbar" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-dark navbar-toggler-icon" style={{stroke:"white"}}></span>
                    </button>
                    <div className="collapse navbar-collapse" id="defaultNavbar">
                        <ul className="navbar-nav w-100 justify-content-center">
                            <li className="nav-item px-3">
                                {/* TODO Update the Active color to a different color */}
                                <a className={"default nav-link " + (props.selected_nav === "Home" ? "active" : "")} aria-current="page" href="/" onClick={() => changePath("/")}>Home</a>
                            </li>
                            <li className="nav-item px-3">
                                <a className={"default nav-link " + (props.selected_nav === "About" ? "active" : "")} href="/about" onClick={() => changePath("/about")}>About Me</a>
                            </li>
                            <li className="nav-item px-3">
                                <a className={"default nav-link " + (props.selected_nav === "Portfolio" ? "active" : "")} href="/portfolio" onClick={() => changePath("/portfolio")}>Portfolio</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    } else if (props.theme === "Destiny")
    {
        return (
            <nav className="destiny navbar navbar-expand-md d-nav m-0">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#destinyNavbar" aria-controls="destinyNavbar" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-dark navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="destinyNavbar">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className={"destiny nav-link d-nav-text " + (props.selected_nav === "Home" ? "active" : "")} aria-current="page" href="/" onClick={() => changePath("/")}>Home</a>
                        </li>
                        <li className=" nav-item ">
                            <a className={" destiny nav-link d-nav-text " + (props.selected_nav === "About" ? "active" : "")} href="/about" onClick={() => changePath("/about")}>About Me</a>
                        </li>
                        <li className="nav-item ">
                            <a className={"destiny nav-link d-nav-text " + (props.selected_nav === "Portfolio" ? "active" : "") } href="/portfolio" onClick={() => changePath("/portfolio")}>Portfolio</a>
                        </li>
                    </ul>
                    </div>
                </div>
            </nav>
        );
    } else if (props.theme === "COD")
    {
        return (
            <nav className="cod navbar navbar-expand-md m-0">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#codNavbar" aria-controls="codNavbar" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-dark navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="codNavbar">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className={"cod nav-link active d-nav-text " + (props.selected_nav === "Home" ? "active" : "")} aria-current="page" href="/" onClick={() => changePath("/")}>Home</a>
                        </li>
                        <li className=" nav-item ">
                            <a className={" cod nav-link d-nav-text "+ (props.selected_nav === "About" ? "active" : "")}  href="/about" onClick={() => changePath("/about")}>About Me</a>
                        </li>
                        <li className="nav-item ">
                            <a className={"cod nav-link d-nav-text " + (props.selected_nav === "Portfolio" ? "active" : "")} href="/portfolio" onClick={() => changePath("/portfolio")}>Portfolio</a>
                        </li>
                    </ul>
                    
                    </div>
                </div>
            </nav>
        );
    }
    
  }

  export default NavBar;